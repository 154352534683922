/* Buttons, links, labels, progress bar */

/**
Buttons
*/
$top-banner-button-margin: 3px 0 2px 5px;
$top-banner-button-margin-medium: 0 0 0 5px;
$top-banner-button-padding: .3875rem 1rem .3975em;
$top-banner-button-icon-padding: .3rem 1rem .36em;
$top-banner-button-border: 1px solid $white;
$button-icon-size: $medium-font-icon;
$button-icon-padding: 0.711em 1.5em 0.771em;
$label-icon-size: $medium-font-icon;
$button-icon-small-size: $small-font-icon;
$button-icon-tiny-size: $tiny-font-icon;
$link-icon-size: $small-font-icon;
$large-link-icon-size: $large-font-icon;
$small-link-icon-size: $tiny-font-icon;
$small-button-padding: 0.5rem 0.8rem 0.506rem;
$tiny-button-padding: 0.3rem 0.8rem 0.306rem;;

.button {
    //border: 1px solid $primary-color;
    line-height: 1.2;
    &:focus {
        outline: none;
    }
    &.primary {
        color: $white;
        &:hover, &:active, &:visited {
            color: $white;
        }
    }
    &.secondary {
        border: 1px solid $medium-gray;
    }
    &.small {
        padding: $small-button-padding;
        margin: 0.5em 0;
    }
    &.tiny {
        padding: $tiny-button-padding;
        margin: 0.25em 0;
    }
    &.success, &.warning, &[disabled].success, &[disabled].warning {
        color: $light-text;
    }
    &.success:hover, &.warning:hover, &[disabled].success:hover, &[disabled].warning:hover, &.success:focus, &.warning:focus, &[disabled].success:focus, &[disabled].warning:focus {
        color: $light-text;
    }
    &--icon {
        padding: $button-icon-padding;
        .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
            margin-right: 8px;
            font-size: $button-icon-size;
            position: relative;
            top: 1px;
        }
        &--left {
            @extend .button--icon;
            .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
                margin-left: 8px;
                margin-right: 0;
            }
        }
        &.small {
            .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
                font-size: $button-icon-small-size;
                margin-right: 6px;
                top: 0;
            }
        }
        &.tiny {
            .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
                font-size: $button-icon-tiny-size;
                margin-right: 5px;
                top: 0;
            }
        }
    }
    &--only-icon {
        padding: $button-icon-padding;
        .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
            margin-right: 0;
            font-size: $button-icon-size;
        }
        &.small {
            .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
                font-size: $button-icon-small-size;
            }
        }
        &.tiny {
            .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
                font-size: $button-icon-tiny-size;
            }
        }
    }
    &--top-banner {
        border: $top-banner-button-border;
        padding: $top-banner-button-padding;
        margin: $top-banner-button-margin;
        @include breakpoint(medium) {
            margin: $top-banner-button-margin-medium;
        }
    }
    &--top-banner-only-icon {
        border: $top-banner-button-border;
        padding: $top-banner-button-icon-padding;
        margin: $top-banner-button-margin;
        @include breakpoint(medium) {
            margin: $top-banner-button-margin-medium;
        }
        .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
            margin-right: 0;
            font-size: $button-icon-size;
            position: relative;
            top: 2px;
        }
    }
    &--top-banner-icon {
        border: $top-banner-button-border;
        padding: $top-banner-button-icon-padding;
        margin: $top-banner-button-margin;
        &:first-child {
            margin-left: 0;
        }
        @include breakpoint(medium) {
            margin: $top-banner-button-margin-medium;
        }
        .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
            margin-right: 8px;
            font-size: $button-icon-size;
            position: relative;
            top: 1px;
        }
    }
    &--control__box {
        margin: 15px auto 5px auto;
        width: 270px;
        text-align: center;
        white-space: nowrap;
        height: 34px;
        overflow: visible;
        &::after {
            clear: both;
            content: "";
            display: block;
        }
        .button--control {
            margin: 0;
            display: block;
            float: left;
            width: 33.33%;
            height: 100%;
            border-radius: 0;
            font-size: 20px;
            padding: 0;
            & + input {
                width: 33.33%;
                height: 100%;
                display: block;
                float: left;
                margin-bottom: 0;
                position: relative;
                font-size: 100%;
                padding: 0.4em;
                text-align: center;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
                }
            }
            &:first-child {
                border-top-left-radius: $global-radius;
                border-bottom-left-radius: $global-radius;
                margin-right: -1px;
            }
            &:last-child {
                border-top-right-radius: $global-radius;
                border-bottom-right-radius: $global-radius;
                margin-left: -1px;
            }
        }
        &.small {
            width: 140px;
            height: 28px;
            margin-top: 5px;
            margin-bottom: 0;
            .button--control {
                font-size: 16px;
            }
        }
    }
    &--status {
        width: 25px;
        height: 25px;
        border: 1px solid $dark-gray;
        margin: 2px;
        display: inline-block;
        text-align: center;
        padding: 1px 0;
        background: transparent;
        color: $dark-gray;
        cursor: pointer;
        font-size: 19px !important;
        &:hover {
            background: $primary-color;
            color: $white;
        }
        &:not(.current_status):hover {
            background: $medium-gray;
            color: $white;
        }
        &:focus, &:active {
            outline: none;
            background: $primary-color;
        }
        &.current_status {
            background: $primary-color;
            color: $white;
        }
        .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
            margin: 0;
            display: inline-block;
            font-size: 19px !important;
            height: 23px;
        }
    }
}

label + .button {
    margin: 0.25em 0;
}

.close-button {
    background: transparent;
    color: $medium-gray;
    top: 0.15em;
    right: 0.4em;
    font-size: $medium-font-icon;
    &:hover, &:focus {
        background: transparent;
        outline: none;
    }
    &:hover {
        color: $extra-dark-gray;
    }
}

/**
Button groups
*/
.button-group {
    position: relative;
    margin: 1rem 0 0;
    overflow: hidden;
    &::before, &::after {
        content: "";
    }
    &:not(.center) {
        .button {
            display: block;
            float: left;
        }
    }
    &.secondary .button {
        border: 1px solid $medium-gray;
    }
    &.small {
        .button {
            padding: $small-button-padding;
        }
        .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
            font-size: $button-icon-small-size;
        }
    }
    &.tiny {
        .button {
            padding: $tiny-button-padding;
        }
        .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
            font-size: $tiny-font-icon;
            margin-right: 5px;
            top: 0;
        }
    }
    &.center {
        display: block;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    &--tabs {
        margin-bottom: 0;
        z-index: 2;
        .button {
            border: 1px solid $button-group-tab;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            background: $button-group-tab;
            padding: 0.8em 1em 0.806em;
            margin-bottom: 0;
            &:hover {
                background: $navigation-background;
            }
            &:first-child:last-child {
                max-width: 100%;
            }
            @for $i from 2 through $buttongroup-expand-max {
                &:first-child:nth-last-child(#{$i}) {
                    &, &:first-child:nth-last-child(#{$i}) ~ .button {
                        max-width: calc(#{percentage(1 / $i)} - #{$buttongroup-spacing});
                        margin-#{$global-right}: $buttongroup-spacing;

                        &:last-child {
                            margin-#{$global-right}: $buttongroup-spacing * -$buttongroup-expand-max;
                        }
                    }
                }
            }
        }
        .active-tab {
            background: $white;
            border: 1px solid $light-gray;
            border-bottom: 1px solid $white;
            color: $body-font-color;
            &:hover {
                background: $white;
            }
        }
        & + .callout {
            margin-top: -1px;
            border-top-left-radius: 0;
            padding: 1em;
            z-index: 1;
            .button-group--tabs__content {
                display: none;
                &.active-content {
                    display: block;
                }
            }
        }
        & + .button-group__table-container.callout {
            padding: 0;
        }
        &.expanded {
            & + .callout {
                border-top-right-radius: 0;
            }
        }
        &.stacked-for-small {
            .button {
                @include breakpoint(small only) {
                    border-radius: $global-radius;
                    width: 100% !important;
                    max-width: 100% !important;
                    &:last-child.active-tab {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
                @include breakpoint(medium) {
                    flex: 0 0 auto;
                }
            }
        }
    }
    &__table-container {
        border: 1px solid $panel-border;
    }
    &__table {
        width: 100%;
        margin: 0;
        border: 0;
        thead, tbody, tfoot {
            border: 0;
        }
    }
    &.status {
        white-space: nowrap;
        text-align: center;
        .button {
            display: inline-block;
            float: none;
        }
    }
}

/**
Menu buttons
*/
.menu--button {
    overflow: hidden;
    li {
        display: block;
        float: left;
        & + li {
            margin-left: 5px;
        }
        a {
            @extend .button;
            margin: 0.5em 0;
        }
        a:hover, a:focus {
            background: $primary-color;
        }
        &:not(.active) {
            a:not(.active) {
                @extend .button.secondary;
            }
        }
    }
    &__content {
        display: none;
        margin-top: 1em;
        &.active-content {
            display: block;
        }
    }
    &.small {
        li a {
            @extend .button.small;
        }
        .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
            font-size: $small-font-icon;
        }
    }
    &.tiny {
        li a {
            @extend .button.tiny;
        }
        .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
            font-size: $tiny-font-icon;
        }
    }
    &.stacked-for-small {
        @include breakpoint(small only) {
            li {
                float: none;
                margin-left: 0;
            }
            a {
                display: block;
            }
        }
    }
}

/**
Links
*/
.link {
    &--icon {
        .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
            font-size: $link-icon-size;
            margin: 0;
            position: relative;
        }
        margin-right: 15px;
        &.large {
            .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
                font-size: $large-link-icon-size;
            }
        }
    }
    &--icon-list {
        white-space: nowrap;
        .link--icon {
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &--secondary {
        color: $extra-dark-gray;
        background: transparent;
        transition: none;
        padding: 0 0.1em 0.05em 0.1em;
        border-bottom: 1px solid transparent;
        border-radius: 0;
        display: inline-block;
        margin-top: 1em;
        cursor: pointer;
        &:hover {
            color: $primary-color;
            border-bottom: 1px solid $primary-color;
            background: transparent;
            outline: none;
        }
        &:focus, &:active, &:visited {
            background: transparent;
            outline: none;
        }

        &.backtotop-button {
            -webkit-appearance: none;
            border: none;
            &:hover {
                border: none;
            }
        }
        .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
            top: 1px;
        }
    }
    &--inverse {
        color: $light-text;
        &:hover, &:focus, &:active, &:visited {
            color: $light-text;
            outline: none;
        }
        .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
            margin-right: 5px;
            font-size: 90%;
        }
    }
}
a.backtotop-button {
    @extend .link--secondary;
    @extend .link--secondary.backtotop-button;
}

/**
Labels
 */
.label {
    margin: {
        right: 0;
        bottom: 7px;
    }
    min-width: 60px;
    border: 1px solid $light-blue;
    text-align: center;
    &__list {
        margin: 0.5em 0;
        overflow: hidden;
        .label {
            margin-right: 7px;
            display: block;
            float: left;
        }
        .label__container {
            white-space: nowrap;
            float: left;
            .svg__container--small {
                margin-left: -0.5em;
                float: left;
            }
        }
    }
    &--icon {
        padding: 0.32rem 0.8rem 0.38em;
    }
    .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
        font-size: $label-icon-size;
        margin-right: 7px;
        position: relative;
        top: 1px;
        display: inline-block;
    }
    &.primary, &.success, &.warning, &.alert {
        color: $light-text;
    }
    &.secondary {
        color: $body-font-color;
        border: 1px solid #ccc;
    }
    &.success {
        border: 1px solid $success-color;
    }
    &.warning {
        border: 1px solid $warning-color;
    }
    &.alert {
        border: 1px solid $alert-color;
    }
    &.aqp {
        background: $aqp-icon;
        border: 1px solid $aqp-icon;
    }
    &.small {
        font-size: $small-font-size-px;
        padding: 0.35rem 0.65rem 0.4em;
        min-width: 25px;
    }
}

/**
Highlighted text
 */
.highlight {
    display: inline-block;
    padding: 0.265em 0.75em 0.31em;
    background: $light-blue;
    color: $light-text;
    margin-right: 5px;
    text-align: center;
    &.primary {
        background: $primary-color;
    }
    &.success {
        background: $success-color;
    }
    &.warning {
        background: $warning-color;
    }
    &.alert {
        background: $alert-color;
    }
    &.secondary {
        background: $dark-gray;
    }
    &.expanded {
        display: block;
        margin: 0;
    }
    &.small {
        font-size: $small-font-size-px;
    }
}

/**
Progress bar
 */
.progress-bar {
    height: 24px;
    background: $extra-light-gray;
    border-radius: 4px;
    margin-top: 0.5em;
    &__bar {
        height: 100%;
        background: $light-blue;
        border-radius: 4px 0 0 4px;
        text-align: right;
    }
    &__text {
        display: inline-block;
        margin: 0px 5px;
        color: $light-text;
        position: relative;
        top: -1px;
    }
}