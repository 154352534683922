.DateInput_input__small {
  height: 37px;
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
}

#quality-questionnaires {
  select {
    padding-right: 0;
  }
  #outer-content-wrap {
    margin-left: 0 !important;
  }
  #content-wrap {
    padding: 0 1em;
    @include breakpoint(medium) {
      padding: 0 0.5em 0 200px;
    }
  }
  .questionnaire-wrap {
    @include breakpoint(medium) {
      padding: 0 1em;
    }
  }
  .navbar {
    width: 200px !important;
    position: fixed !important;
    top: 0;
    left: 0;
    padding-top: 60px;
    z-index: 505;
    display: none;
    &__content {
      margin-top: 60px;
      padding-bottom: 120px;
      overflow: auto;
      @include breakpoint(large) {
        margin-top: 25px;
        padding-bottom: 60px;
      }
    }
    @include breakpoint(medium) {
      display: block;
    }
    @include breakpoint(large) {
      padding-top: 0;
      z-index: 805;
    }
    &__title {
      cursor: default;
      border-top: 0;
      border-bottom: 1px solid #b3b3b3;
      text-align: left;
      padding: 1.1em 5px 10px 25px;
    }
    &__subtitle {
      color: $white;
      padding-left: 24px;
      margin: 10px 0 5px;
      display: block;
    }
    &__link {
      max-height: none;
      padding: 0.4em 10px 0.4em 35px;
      line-height: 1.4;
    }
    &__service-users {
      margin: 5px 25px;
    }
    &__save-button {
      padding: 0 25px;
      border-top: 1px solid #b3b3b3;
      .button {
        margin: 0.75em 0 0.5em;
        &:hover {
          background: $main-blue-lighter-background;
        }
      }
    }
  }
  .info-box__title {
    color: $blue-title;
  }

  .section {
    margin-top: 1em;
    @include breakpoint(medium) {
      margin-top: 1.5em;
    }
    &__title {
      border-bottom: 1px solid $light-gray;
      padding: 0 1em 0.65em;
      margin: 0 -1em 0.25em -1em;
      color: $blue-title;
      @include breakpoint(medium) {
        padding: 0 0.75em 0.65em;
        margin: 0 -1.5em 0 -1.5em;
      }
      h5 {
        margin-bottom: 0.1em;
      }
    }
    &__reporting-periods {
      text-align: center;
      font-size: $small-font-size;
      white-space: nowrap;
      position: relative;
      display: none;
      @include breakpoint(medium) {
        display: block;
      }
      @include breakpoint(large) {
        position: relative;
        top: -1.75em;
      }
      .columns {
        @include breakpoint(large) {
          &:nth-child(2) {
            position: relative;
            left: 2.5em;
          }
        }
      }
    }
    &__reporting-periods--small {
      font-size: $small-font-size;
      white-space: nowrap;
      display: block;
      margin-top: 10px;
      @include breakpoint(medium) {
        display: none;
      }
    }
    &__comments-icon {
      display: inline-block;
      margin-top: 2em;
      color: $primary-color;
      font-size: 20px;
      @include breakpoint(medium) {
        margin-top: 0.5em;
      }
      @include breakpoint(large) {
        margin-top: 0.1em;
      }
    }
    label {
      line-height: 1.5;
      font-size: 1em;
    }
    &__input-label {
      font-size: $small-font-size;
    }
    &__name-field, &__position-field {
      @include breakpoint(large) {
        position: relative;
        left: -1.75em;
      }
    }
    &__question {
      margin: 0 -0.5em;
      input {
        margin: 5px 0 25px 0;
      }
      .columns {
        @include breakpoint(medium) {
          padding: 0.5em;
        }
        @include breakpoint(large) {
          &:nth-child(2), &:nth-child(3) {
            padding: 0 2.3em;
          }
          &:nth-child(2) {
            position: relative;
            left: 2em;
          }
          &:nth-child(2).large-offset-2 {
            left: 0;
            & + .columns.end {
              padding: 0.5em;
            }
          }
        }
      }
    }
    &__question-alltext {
      position: relative;
      top: 0.2em;
    }
    &__question-text {
      &.is-invalid-label__part .section__question-number {
        color: $alert-color;
      }
    }
    &__question-text__part {
      padding-left: 12px;
    }
    &__question-number {
      font-size: 1.1em;
      font-weight: bold;
    }
    .form-error {
      margin-top: 5px;
    }
  }

  .tickbox {
    display: inline-block;
    text-align: center;
    width: 100%;

    .icon {
      border-radius: 50%;
      padding: 5px;
      font-size: 25px;
      background: gray;
      color: #eee;
      &__checked {
        @extend .icon;
        background: $green;
      }
    }
  }
  .placement {
    &__title {
      padding: 0 1em 0.65em;
      margin: 0 -1em 0.75em -1em;
      border-bottom: 1px solid $light-gray;
      color: $blue-title;
      @include breakpoint(medium) {
        padding: 0 1.25em 0.65em;
        margin: 0 -1.25em 0.75em -1.25em;
      }
    }
    p {
      margin-bottom: 0.5em;
    }
    &__table {
      text-align: center;
      margin-bottom: 0;
      border: 1px solid $light-gray;
      input {
        margin: 0;
      }
      .section__comments-icon {
        margin-top: 0;
      }
      &__input {
        margin: 0;
        display: inline-block;
        width: 100%;
        padding-left: 0;
        padding-right: 25px;
        @include breakpoint(large) {
          padding-left: 1em;
        }
      }
      &__icon {
        display: inline-block;
        width: 30px;
        margin-left: -30px;
      }
    }
    &__table-header, &__table-header--small {
      padding: 0.5em 0;
      margin: {
        right: 0;
        left: 0;
      }
      clear: both;
      font-weight: bold;
      &__columns {
        padding: 0;
        @include breakpoint(large) {
          padding: 0 100px 0 0;
        }
      }
    }
    &__table-row {
      margin: {
        right: 0;
        left: 0;
      }
      border-bottom: 1px solid $light-gray;
      &:last-child {
        border-bottom: 0;
      }
      @include breakpoint(large) {
        border-bottom: 0;
      }
      &:nth-child(2n) {
        background: $table-row-background;
        @include breakpoint(large) {
          display: block;
        }
      }
      .columns {
        padding: 15px 7px;
      }
    }
    &__table-header {
      display: none;
      @include breakpoint(large) {
        display: block;
      }
      .columns {
        padding: 0 7px;
      }
    }
    &__table-header--small {
      padding: 0;
      width: 98%;
      margin: 0 1%;
      .columns {
        padding-bottom: 0;
      }
      @include breakpoint(large) {
        display: none;
      }
    }
    &__table-content {
      width: 100%;
      padding: 0;
      @include breakpoint(large) {
        padding: 0 100px 0 0;
      }
    }
    &__table-content, &__table-header__columns {
      overflow: visible;
      & > .columns.col1 {
        width: 33%;
      }
      & > .columns.col2 {
        width: 33%;
      }
      & > .columns.col3 {
        width: 33%;
      }
      & > .columns.col4 {
        width: 33%;
      }
      & > .columns.col5 {
        width: 66%;
      }
      @include breakpoint(large) {
        & > .columns.col1 {
          width: 21%;
        }
        & > .columns.col2 {
          width: 18%;
        }
        & > .columns.col3 {
          width: 18%;
        }
        & > .columns.col4 {
          width: 11%;
        }
        & > .columns.col5 {
          width: 32%;
        }
      }
    }
    &__table-button {
      width: 98%;
      margin: 0 1%;
      border-top: 1px solid $light-gray;
      @include breakpoint(large) {
        width: 100px;
        margin: 0;
        position: absolute;
        top: 1px;
        right: 1em;
        border: 0;
      }
      .section__comments-icon {
        position: relative;
        top: 0.25em;
        right: -0.5em;
        @include breakpoint(large) {
          width: 20px;
          margin: 0;
          position: absolute;
          top: 0.6em;
          right: -0.25em;
        }
      }
    }
    &__table-button.columns {
      padding: 10px 0;
    }
    &__add-button .button {
      margin-bottom: 0;
    }
  }

  .multi-question {
    &__titles > div, &__titles--small > div {
      text-align: center;
    }
    &__table {
      overflow: hidden;
      margin: 1em 0;
      select, input {
        margin: 0.6em 0;
      }
    }
    &__header {
      border-bottom: 1px solid $light-gray;
      border-top: 1px solid $light-gray;
      padding: 0;
      margin: 0;
      text-align: center;
      display: none;
      @include breakpoint(large) {
        display: block;
      }
      & > .columns {
        padding: 0.5em 0.25em 0;
        &:nth-child(2) {
          @include breakpoint(large) {
            border-left: 1px solid $light-gray;
          }
        }
      }
      strong {
        display: inline-block;
        margin-bottom: 0.1em;
      }
    }
    &__titles {
      display: none;
      font-size: $small-font-size;
      @include breakpoint(large) {
        display: block;
        font-size: $global-font-size;
      }
      .columns {
        padding: 0 0.25em;
      }
    }
    &__titles--small {
      display: block;
      font-size: $small-font-size;
      margin-bottom: 0em;
      margin-top: 1.25em;
      @include breakpoint(large) {
        display: none;
      }
    }
    &__row {
      border-bottom: 1px solid $light-gray;
      padding: 1em 0;
      @include breakpoint(large) {
        padding: 0;
      }
      &:nth-child(2n) {
        background: $table-row-background;
      }
      .tickbox {
        padding: 0.5em 0 0;
      }
      .form-error {
        position: relative;
        margin: 0;
        top: 0.75em;
      }
      & > .row {
        padding: 0;
        margin: 0;
      }
    }
    &__select {
      padding-left: 15px;
      .form-error {
        position: relative;
        margin: 0;
        top: -0.25em;
      }
    }
  }
  .DateInput_input {
    color: $black;
  }
  .DateInput__small {
    width: 100%;
  }

  .input-group {
    margin: 0;
  }
}

.SingleDatePicker_picker {
  z-index: 3 !important;
}

.reveal {
  top: 28px !important;
}
