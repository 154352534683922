/* Color variables */

/**
General
*/
$orange: $warning-color;
$red: $alert-color;
$green: $success-color;
$aqp-icon: #fecf4d;

/**
Background
*/
//$page-background: #f2f2f2;
//$main-blue-background: #2980b9;
$main-blue-lighter-background: #2e90d1;
$darker-main-blue: #206592;
$overlay-background: rgba(10,10,10,0.4);
$inactive-background: $extra-light-gray;

/**
Text
*/
//$dark-text: #222;
$light-text: $white;
$grey-text: $dark-gray;
$blue-title: #003087;
$inactive-text: $extra-dark-gray;

.text {
    &--primary {
        color: $primary-color !important;
    }
    &--success {
        color: $success-color !important;
    }
    &--warning {
        color: $warning-color !important;
    }
    &--alert {
        color: $alert-color !important;
    }
    &--black {
        color: $body-font-color !important;
    }
    &--grey {
        color: $grey-text !important;
    }
}

/**
Navigation
*/
$navigation-background: $dark-blue;
$navigation-hover-background: rgba(255,255,255,0.3);
$navigation-border: #b3b3b3;

$button-group-tab: rgba(36, 69, 100, 0.85);

/**
Border
*/
$panel-border: $light-gray;
$panel-box-border-color: $medium-gray;

/**
CQC rating
*/
$rating-outstanding: $success-color;
$rating-good: $success-color;
$rating-improvement: $warning-color;
$rating-inadequate: $alert-color;
$rating-notinspected: $dark-gray;

/**
Charts
*/
$line-chart-line1: $primary-color; /* #2980b9 */
$line-chart-line2: #8f9ea5;
$line-chart-line3: #245979;
$line-chart-line4: #75b2e4;

$bar-chart-bar1: $primary-color; /* #2980b9 */
$bar-chart-bar2: #8f9ea5;
$bar-chart-bar3: #245979;
$bar-chart-bar4: #75b2e4;
