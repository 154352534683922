/* general styling for print layout */

/* do not use page specific styling */

.internal-page__print-logo {
    display: none;
}

@media print {
    @page {
        margin: {
            top: 1.5cm;
            bottom: 1.5cm;
        }
    }
    .hide-on-print {
        display: none !important;
    }
    .prevent_break_inside {
        page-break-inside: avoid;
    }
    .pagebreak {
        page-break-after: always;
    }
    .login, .footer, .navbar, .internal-navigation__container, .breadcrumbs, #carepulse_logo {
        display: none !important;
    }
    #body {
        padding-bottom: 0;
    }
    #page_title {
        padding-left: 1em !important;
        outline: 0 !important;
        margin-bottom: 0;
    }
    #carepulse_logo, #lph_logo {
        display: block;
        margin-bottom: 1em;
    }
    .top-banner {
        &__logo {
            padding-left: 0 !important;
        }
        &__title h2 {
            font-size: 1rem;
        }
    }
    body {
        margin: {
            top: 0;
            bottom: 0;
        }
    }
    #content-wrap {
        padding-left: 0 !important;
        width: 100% !important;
    }
    #outer-content-wrap {
        margin-left: 0 !important;
    }
    #inner-content {
        width: 100%;
        padding: 0 2em;
        margin-top: 1em;
    }
    .internal-page {
        &__header {
            font-size: 20px !important;
            margin: 1rem 0 !important;
            padding: 0;
            font-weight: bold;
            position: relative;
            top: 0.5em;
        }
        &__print-logo {
            display: inline;
            position: absolute;
            top: 0;
            left: 1.5em;
            width: auto;
            height: 49px;
        }
    }
    a[href]:after {
        content: none !important;
    }
    .stacktable {
        &.small-only {
            display: none !important;
        }
        &.large-only {
            display: table !important;
        }
    }
}