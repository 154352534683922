
#cost_template_registration {
  .cqc-box {
    margin-top: 10px;
    max-height: 400px;
    overflow: auto;
    margin-bottom: 10px;
    border: 1px solid #aaa;
    padding: 10px;
    font-style: italic;
    background: #e6e6e6
  }

  .form-heading {
    padding: 0px 15px;
  }

  .row-fields {
    margin-bottom: 10px;
  }

  input {
    background-color: #fdfdfd !important;
  }

  .alert-link {
    color: #fff;
    text-decoration: underline;
  }
}
