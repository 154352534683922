/* Resetting Foundation and Browser defaults */

html {
    height: 100%;
}
body {
    height: 100%;
}
//.row .row {
//    margin: 0;
//}
.span-font {
    font-size: 16px;
}
.row {
    max-width: none;
}
button {
    background: $primary-color;
}
.small-centered, .medium-centered, .large-centered {
    margin-left: auto;
    margin-right: auto;
}
a[type="button"] {
    -webkit-appearance: none;
}

/**
Form elements
 */
.input-group-label {
    padding: 0 0.5em;
}
.fieldset {
    background: $white;
    legend {
        font-weight: bold;
    }
}
.radio-button__container, .checkbox__container {
    display: inline-block;
    margin: {
        right: 15px;
        bottom: 0.75rem;
    }
    input {
        margin: {
            right: 7px;
            bottom: 0;
        }
    }
    &--block {
        display: block;
        margin: {
            right: 0;
            bottom: 0.75rem;
        }
        input {
            margin: {
                right: 7px;
                bottom: 0;
            }
        }
    }
}
form legend {
    margin-top: 0.5rem;
}
.is-invalid-input:not(:focus) {
    background: $white;
}
#filter_panel select {
    padding-right: 1.5rem;
}
#submit-clear-filters {
    margin-top: 1rem;
}

/**
Contact edit form
*/
div.tag-formset, div.email-formset, div.phone-formset, div.pa-email-formset, div.pa-phone-formset, div.position-formset {
    position: relative;
}
div.tag-formset > a > .fa-trash-alt, div.email-formset > a > .fa-trash-alt, div.phone-formset > a > .fa-trash-alt, div.position-formset > a > .fa-trash-alt, div.pa-email-formset > a > .fa-trash-alt, div.pa-phone-formset > a > .fa-trash-alt {
    position: absolute;
    right: 0;
    bottom: 0.3em;
    margin-right: 0px;
    color: #cc0000;
}
div.email-formset > a > .fa-trash-alt, div.phone-formset > a > .fa-trash-alt, div.position-formset > a > .fa-trash-alt {
    bottom: 1.2em;
}
div.tag-formset > div.holder, div.email-formset div.row, div.phone-formset > div.row, div.position-formset div.holder, div.pa-email-formset > div.row, div.pa-phone-formset > div.row {
    padding-right: 30px;
}

/**
Making full width select2 input fields
*/
.select2-search__field, .select2-search, .select2-container {
    width: 100% !important;
}

/**
Styling select2 input fields
*/
.select2 select2-container select2-container--default select2-container--below {
    width: auto;
}
.select2-search__field, .select2-selection__choice, .select2-results__options, .ui-menu-item {
    font-size: $small-font-size;
}
span.select2-selection.select2-selection--multiple {
    border-radius: 0;
}
.select_plugin {
    margin-bottom: 1rem;
}
input.select2-search__field {
    height: 1.875rem;
    margin: 0;
}

/**
Fixing React Select's drop down overlapping issue
*/
.Select-menu-outer {
    z-index: 405;
}

/**
Styling React Select's drop down
*/
.Select {
    margin-bottom: 1rem;
    .Select-control {
        border: {
            radius: 0;
            color: $medium-gray;
        }
    }
    .Select-value-label {
        display: inline;
    }
}
.is-invalid-label {
    .Select-control {
        border-color: $alert-color;
    }
}
.form-error {
    line-height: 1.3;
}

/**
Overwriting Foundation defaults for Tabs plugin to make it consistent with custom button-group tabs
*/
.tabs {
    border: 0;
    z-index: 2;
    &-title {
        a {
            color: $white;
            border-radius: $global-radius $global-radius 0 0;
            background: $button-group-tab;
            border: 1px solid $button-group-tab;
            border-bottom: 0;
            margin-right: 5px;
            outline: 0 !important;
            &:hover {
                background: $navigation-background;
                color: $white;
            }
        }
        &.is-active a {
            background: $white;
            border: 1px solid $light-gray;
            border-bottom: 0;
            &:hover {
                background: $white;
                color: $body-font-color;
            }
        }
    }
    &-content {
        border-top: 1px solid $light-gray;
        border-radius: 5px;
        margin-top: -1px;
        margin-bottom: 10px;
        border-top-left-radius: 0;
        z-index: 1;
    }
    &-panel > :last-child {
       margin-bottom: 0;
    }
}
.tabs.vertical {
    .tabs-title {
        a {
            border-radius: $global-radius;
            margin-right: 0;
        }
        &:last-child.is-active a {
            border-bottom: 0;
            border-radius: $global-radius $global-radius 0 0;
        }
    }
    & + .tabs-content {
        border-top: 0;
    }
    &.center {
        .tabs-title {
            a {
                text-align: center;
            }
        }
    }
}
