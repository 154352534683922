#hospital-map {
    @media print {
        .ReactTable {
            font-size: 12px;
        }
        .rt-tbody {
            height: auto;
        }
        .ReactTable .rt-tbody .rt-td {
            padding: 0.3em 0.75em;
        }
        #map .point {
            background: #c1e1f6 !important;
        }
    }
}