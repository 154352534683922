/* Notification/Announcement panel */
/* Currently: on provider homepage */

/**
Notifications in announcement panel
 */
.notification {
  border-radius: $global-radius;
  margin-bottom: 6px;
  border: 1px solid $panel-box-border-color;

  &__title-container {
    padding: 18px;
    padding-bottom: 5px;
    border-bottom: 1px solid $panel-box-border-color;
    &__title {
      font-size: 16px;
      font-weight: 600;
    }
    &__date {
      font-size: 14px;
      float: right;
    }
  }

  &__body {
    padding: 18px;
    padding-bottom: 9px;
    font-size: 15px;
    p {
      margin-bottom: 5px;
      //font-size: 15px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &__footer {
    padding: 18px;
    padding-top: 0;
    //padding-bottom: 30px;
    font-size: 15px;
  }
  &:hover {
    cursor: pointer;
  }
}

/**
Notifications in pop-up
 */
.reveal--notification {
  .reveal__title {
    color: $blue-title;
    border-bottom: 1px solid $panel-box-border-color;
    font: {
      weight: bold;
      size: 1.25em;
    }
    padding: {
      left: 1em;
      right: 1em;
      bottom: 0.65em;
    }
    margin: 0.15em -1em 0.5em;
    @include breakpoint(medium) {
      padding : {
        left: 1.5em;
        right: 115px;
        top: 0.25em;
      }
      margin: 0.15em -1.5em 1em;
    }
  }
  .attachments {
    border-top: 1px solid $panel-box-border-color;
    padding-top: 1em;
    margin: {
      left: -1em;
      right: -1em;
    }
    padding: {
      left: 1em;
      right: 1em;
    }
    @include breakpoint(medium) {
      margin: {
        left: -1.5em;
        right: -1.5em;
      }
      padding: {
        left: 1.5em;
        right: 1.5em;
      }
    }
    &__attachment {
      //display: inline-block;
      cursor: pointer;
      margin-right: 20px;
      white-space: nowrap;
      position: relative;
      padding-left: 45px;
      &__title {
        margin-bottom: 0px;
        font-size: 17px;
        white-space: normal;
      }
      &__icon {
        font-size: $xlarge-font-icon;
        margin-right: 10px;
        display: inline-block;
        position: absolute;
        top: -3px;
        left: 0;
        .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
          font-size: $xlarge-font-icon;
        }
      }
      &__info {
        display: inline-block;
        p {
          font-size: 14px;
          margin-top: 0;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
