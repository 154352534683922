#vacancy_overview {
  .save-button {
    margin-top: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=checkbox] {
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    padding: 10px;
  }

  input {
    margin-bottom: 0px;
  }

  .popup {
    &__actions {
      border-top: 1px solid #ccc;
      margin-top: 12px;
    }
  }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: right;
  }

  .vacancy-contact__row {
    border: 1px solid $panel-border;
    border-top: 0;
    padding: 0.5em 0;
    position: relative;
    &:first-child {
      border-top: 1px solid $panel-border;
    }
    &:nth-child(odd) {
      background: #f8f8f8;
    }
    .label {
      margin-top: 5px;
       @include breakpoint(medium only) {
         margin-bottom: 30px;
       }
    }
  }
  .vacancy-reporting {
    &__control-button {
      text-align: left;
      position: relative;
      //padding: 0 0.25em;
      @include breakpoint(medium) {
        text-align: right;
      }
      .button--control__box {
        display: inline-block;
        @include breakpoint(medium) {
          position: absolute;
          right: 1em;
          top: 0;
        }
        &__label {
          display: block;
          position: relative;
          @include breakpoint(medium) {
            display: inline-block;
            padding-right: 155px;
            top: 6px;
            margin-bottom: 15px;
            margin-right: 0;
          }
        }
      }
    }
    &__save-container {
      text-align: center;
      margin: 10px 0 0;
      @include breakpoint(medium) {
        float: right;
        text-align: right;
        margin: 5px 0 0;
      }
    }
    &__vacancy {
      margin-top: 15px;
      font-style: italic;
      text-align: left;
      @include breakpoint(medium only) {
        position: absolute;
        bottom: 0.25rem;
        left: 1rem;
      }
      @include breakpoint(large) {
        float: right;
        text-align: right;
        margin-right: 12px;
        margin-top: 12px;
      }
    }
  }
}