#homepage {
    #container {
        overflow: hidden;
    }
    #contentBox {
        padding: 1em 1em 0 1em;
        width: 100%;
        max-width: 1500px;
        margin-top: 1em;
        @include breakpoint(medium) {
            padding: 1em 1em 0em 1em;
            width: 95%;
        }
        @include breakpoint(large) {
            padding: 0 1em 0em 1em;
        }
    }
    .secondary-navigation {
        position: fixed;
        left: 0;
        &__path {
            @include breakpoint(small only) {
                padding: 5px 3em 7px 2em;
            }
            @include breakpoint(medium only) {
                padding-left: 75px;
                padding-right: 5em;
            }
        }
        .ccg-name {
            display: inline-block;
        }
    }
    @include breakpoint(medium) {
        .custom-list {
            padding-left: 2em;
        }
        .custom-list__select {
            margin: 0;
        }
    }
    @include breakpoint(large) {
        .custom-list {
            padding-left: 0;
            display: inline-block;
            float: right;
        }
        .custom-list__select {
            position: relative;
            top: -2px;
            right: -0.5em;
        }
    }

    .homepage-card {
        margin-bottom: 40px;
        overflow: visible;
        padding: 0 !important;
        .widget-card {
            position: relative;
        }
        &:nth-child(2n+1) .widget-card {
            @include breakpoint(large) {
                margin-right: 2em;
            }
        }
        &:nth-child(2n) .widget-card {
            @include breakpoint(large) {
                margin-left: 2em;
            }
        }
    }
    .widget-label {
        display: inline-block;
        position: absolute;
        top: 8px;
        right: 25px;
        .label--icon {
            margin-right: 0;
        }
    }
    ul.custom-bullet li::before {
        content: "▪";
        color: #2980b9;
        font-size: 18px;
        left: 0px;
        position: absolute;
    }
    ul.custom-bullet li {
        padding-left: 23px;
        padding-right: 23px;
        margin: 0;
        position: relative;
    }
}