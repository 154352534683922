#hospital-map {
  max-width: 1300px;
  margin: auto;

  .map__container {
    height: 525px;
    margin: 1.5em 0;
    @include breakpoint(large) {
      height: 750px;
    }
  }
  #map {
    width: 100%;
    height: 100%;

    .point {
      width: 24px !important;
      height: 24px !important;
      margin: {
        left: -12px;
        top: -12px;
      }
      border-radius: 18px;
      border: 2px solid #666666;
      text-align: center;
      color: #000000;
      background-color: #c1e1f6;
      font-size: 15px;
    }

    .ccg-labels {
      background: none;
      font-size: 12px;
      border: none;
      box-shadow: none;
      color: $white;
    }

    .info_box {
      padding: 12px 10px;
      background: rgb(253, 253, 253);
      color: #000;
      font-size: 12px;
      line-height: 18px;
      border-radius: 5px;
      &:empty {
        display: none;
      }
    }

    .hospital {
      &_name {
        font: {
          weight: 600;
          size: 14px;
        }
      }
      &_users {
        font-size: 13px;
      }
    }

  }
  .rt-tbody {
    min-width: unset;
    height: 375px;
    overflow: hidden;
    overflow-y: scroll !important;
  }
}