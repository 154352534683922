#profile-edit {
    #my_account #registration-multistep {
        .registration__panel {
            height: auto;
            max-width: 100%;
            padding: 0 0.5em 0 0.5em;
            @include breakpoint(medium) {
                padding: 0 1em 0 1em;
            }
            display: block;
            &__title {
                left: 0;
                top: 0;
                position: relative;
                margin-top: 0;
                margin-bottom: 1em;
                @include breakpoint(large) {
                    & + .row {
                        width: 50%;
                        max-width: 450px;
                    }
                }
            }
            .bottom-buttons {
                position: relative;
                padding: 0 1em 2em;
                bottom: 0;
            }
            .next-button {
                float: none;
            }
            .link-previous {
                position: absolute;
                bottom: 0;
                left: 1em;
            }
        }
        .org-type {
            top: 0;
            margin-bottom: 0;
            @include breakpoint(large) {
                margin: 0 -0.5em;
                width: 60% !important;
                max-width: 700px !important;
                & > div {
                    width: 33%;
                    min-width: 150px;
                    padding: 0 0.5em;
                }
            }
        }
    }
}