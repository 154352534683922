$table-cell-border: rgba(0,0,0,0.075);

/**
General table styling
 */
table {
    &.small {
        font-size: 85%;
    }
    &.center {
        th, td {
            text-align: center;
        }
    }
    &.left {
        th, td {
            text-align: left;
        }
    }
    &.header-left {
        th {
            text-align: left;
        }
    }
    &.header-center {
        th {
            text-align: center;
        }
    }
    th.center, td.center {
        text-align: center;
    }
    th.left, td.left {
        text-align: left;
    }
    th.right, td.right {
        text-align: right;
    }
    &.blank-table {
        table-layout: fixed;
        background: none;
        border: none;
        width: 100%;
        tbody, thead, tfoot {
            background: $white;
            border: none;
        }
        tr, tr:nth-of-type(even) {
            background: $white;
        }
    }
}

/**
General styling for DJango tables
 */
.django-table {
    & + .pagination {
        text-align: center;
        position: relative;
        margin-bottom: 2em;
        .next a, .previous a {
            position: absolute;
            top: -3px;
            width: 25%;
            padding: 1.2em 0 1.206em;
            margin: 0;
            color: $extra-dark-gray;
            &:hover, &:focus {
                color: #fff;
            }
        }
        .next a {
            right: 0;
        }
        .previous a {
            left: 0;
        }
        .cardinality {
            width: 46%;
            display: inline-block;
        }
        @include breakpoint(medium) {
            .next a, .previous a {
                top: 0;
                width: 20%;
                padding: 1em 0 1.06em;
            }
            .cardinality {
                width: 55%;
            }
        }
    }
}
.no_cardinality .cardinality {
    visibility: hidden;
}

/**
General styling for React table
 */
.ReactTable {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 100%;
    overflow: visible;
    margin-top: 0.5em;
    &__filter {
        border-radius: 0;
        padding-top: 0.5em;
        padding-bottom: 1.25em;
        &__title {
            margin: 0;
            font-size: 90%;
            font-weight: bold;
        }
        &__label {
            margin-top: 0.75em;
        }
        &__item {
            width: 100%;
            padding: 0.475em;
            display: block;
            &--select {
                margin-bottom: 1px;
                .Select-control {
                    border-radius: 0;
                }
            }
        }
    }
    .rt-th, .rt-td {
        white-space: normal !important;
        overflow: visible !important;
        & > div:first-child {
            min-width: 60px !important;
        }
    }
    .rt-table {
      overflow: visible !important;
    }
    .rt-tbody {
        overflow: visible !important;
        background: $white;
        .rt-td {
            padding: 0.4em 0.75em;
            height: auto;
            border-right: 1px solid $table-cell-border !important;
            &:last-child {
                border-right: 0 !important;
            }
            .hidden-text {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .rt-thead {
        .rt-th, .rt-td {
            padding: 0.4em 0.75em !important;
            border-right: 1px solid $table-cell-border !important;
            &:last-child {
                border-right: 0 !important;
            }
        }
        &.-header {
            font-weight: bold;
            background: $white;
            border-bottom: 1px solid $light-gray;
        }
        .rt-resizable-header {
            background: transparent !important;
            overflow: visible;
            &:focus {
                outline: none !important;
            }
        }
        .rt-resizable-header-content {
            overflow: visible !important;
        }
        .rt-resizable-header:last-child {
            overflow: visible !important;
        }
        &.-filters {
            background:$body-background;
        }
    }
    &.-striped {
        .rt-tr.-odd {
            background: #F8F8F8 !important;
        }
        .rt-tr.-even {
            background: $white !important;
        }
    }
    .rt-resizer {
        min-width: 10px !important;
        width: 10px !important;
        right: -5px !important;
    }
    &--card-container {
        padding-left: 0;
        padding-right: 0;
        @include breakpoint(medium) {
            &:nth-child(2n + 1) {
                padding-left: 0;
                padding-right: 1em;
                clear: both;
            }
            &:nth-child(2n) {
                padding-right: 0;
                padding-left: 1em;
            }
        }
        &__card {
            position: relative;
            margin: 0.5em 0;
        }
        &__card-title {
            font-weight: bold;
            font-size: 1.1em;
        }
    }
    .-pagination {
        .-pageJump input, select {
            margin: 0.2em 0 !important;
        }
    }
}

.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead.-header, .ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .-pagination {
    box-shadow: none !important;
}

div.table-container table.stacktable.small-only {
    width: 100%;
}

#table1 select {
    padding: .5rem 1.2rem .5rem .5rem;
    width: auto;
}

table.panel-table {
    margin-bottom: 0.75em;
    @include breakpoint(medium) {
        margin-bottom: 1.25em;
    }
    td {
        font-size: 100%;
    }
}
.dataTable {
    border: 1px solid $light-gray;
}