/* Navigation for external pages */

.navbar {
  position: absolute;
  top: 67px;
  left: 0;
  z-index: 905;
  height: 44px;
  background: $navigation-background;
  color: $light-text;
  width: 65px;
  font-size: 1em;
  transition: width 0.5s, height 0.35s;
  overflow: hidden;
  padding-bottom: 10px;
  @include breakpoint(medium) {
    width: 75px;
    height: 100%;
    font-size: 0.80em;
    padding-top: 0px;
    z-index: 805;
    padding-bottom: 80px;
  }
  @include breakpoint(large) {
    top: 0;
    position: fixed;
    width: 200px;
    height: 100%;
    padding-bottom: 50px;
  }
  &__box {
    padding-bottom: 0.4em;
    display: none;
  }
  &__header {
    font-size: 18.5px;
    padding: 0.3em 25px;
    cursor: pointer;
    white-space: nowrap;
    height: 40px;
    @include breakpoint(medium) {
      padding: 0.4em 25px;
    }
    @include breakpoint(large) {
      padding: 1.1em 25px;
      height: 66px;
    }
    div {
      opacity: 0;
      transition: all 0.5s;
      top: 12px;
      @include breakpoint(large) {
        opacity: 1;
        top: 25px;
      }
    }
    span {
      margin-left: -4px;
      display: none;
      @include breakpoint(large) {
        display: inline-block;
      }
    }
    .fa-navicon {
      font-size: 18.5px;
      padding-right: 0.25em;
      top: 0px;
      left: -3px;
      @include breakpoint(medium) {
        left: -2px;
        top: 0;
      }
    }
  }
  &__title {
    border-top: 1px solid $navigation-border;
    padding: 0.95em 25px 0.15em;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    margin-bottom: 0.3em;
    font-size: 0.95em;
    @include breakpoint(medium) {
      padding: 1.05em 25px 0.35em 30px;
      text-align: left;
      margin-bottom: 0.5em;
      .navbar__icon {
        display: none;
      }
    }
    @include breakpoint(large) {
      padding: 1.1em 25px 0.4em 30px;
      margin-bottom: 0.3em;
      .navbar__icon {
        display: inline-block;
      }
    }
  }
  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 1em;
  }
  &__listitem {
    position: relative;
    font-size: 90%;
    white-space: nowrap;
  }
  &__link {
    color: $light-text;
    padding: 0.9em 20px 0.9em 20px;
    display: inline-block;
    width: 100%;
    position: relative;
    max-height: 51px;
    overflow: visible;
    @include breakpoint(medium) {
      padding: 0.85em 21px 0.85em 21px;
      max-height: 40px;
    }
    &:active, &:focus, &:visited {
      color: $light-text;
      outline: none;
    }
    &:hover {
      color: $light-text;
      background: $navigation-hover-background;
    }
    &--active {
      background: $navigation-hover-background;
      & + .navbar__icon--indicator {
        display: inline-block;
      }
    }
    &--home {
      @extend .navbar__link;
      padding-top: 1.2em;
      @include breakpoint(medium) {
        padding: {
          top: 1.2em;
          bottom: 0.75em;
        }
        height: 42px;
      }
      .navbar__icon {
        top: 0;
      }
    }
    &-text {
      display: none;
      @include breakpoint(large) {
        display: inline-block;
      }
    }
    .navbar__icon {
      width: 35px;
      text-align: center;
      @include breakpoint(medium) {
        width: 30px;
      }
    }
    .navbar__icon-box {
      display: inline-block;
      width: 35px;
      margin-right: 5px;
      max-height: 20px;
      overflow: visible;
      @include breakpoint(medium) {
        width: 30px;
      }
      .navbar__icon {
        width: 27px;
        margin: 0;
        top: -6px;
        @include breakpoint(medium) {
          width: 21px;
        }
      }
      & + .navbar__link-text {
        display: inline-block;
        position: relative;
        top: -6px;
        left: -3px;
      }
    }
  }
  &__label {
    position: absolute;
    top: 0;
    right: 3px;
    background: #2f9ec3;
    border-radius: 3px;
    padding: 0px 4px;
    font-size: 9px;
  }
  &__icon {
    display: inline-block;
    font-size: 23px;
    position: relative;
    top: 2px;
    margin-right: 5px;
    @include breakpoint(medium) {
      font-size: 19px;
    }
    &--left {
      @extend .navbar__icon;
      margin: 0;
      color: $body-background;
      padding-right: 0;
      transition: opacity 0.5s;
    }
    &--right {
      @extend .navbar__icon;
      margin: 0;
      position: absolute;
      right: 15px;
      top: 1em;
      color: $body-background;
      padding-right: 0;
      transition: opacity 0.5s;
      @include breakpoint(medium) {
        top: 1.2em;
      }
      @include breakpoint(large) {
        top: 1.35em;
      }
    }
    &--indicator {
      @extend .navbar__icon;
      margin: 0;
      position: absolute;
      right: -2px;
      top: 0.25em;
      padding-right: 0;
      display: none;
      color: $body-background;
      @include breakpoint(medium) {
        top: 0.075em;
      }
    }
    &--large {
      @extend .navbar__icon;
      font-size: 36px;
    }
    &--small {
      @extend .navbar__icon;
      font-size: 16px;
      @include breakpoint(medium) {
        font-size: 13px;
      }
    }
  }

  // Questionnaire's styling
  &__section {
    white-space: normal;
    padding: 0.5em 5px 0.5em 37px;
    cursor: pointer;
    color: $light-gray;
    &--active {
      color: orange;
    }
    &:hover {
      color: orange;
    }
  }
  &__check-icon, &__error-icon {
     font-size: 12px;
  }
  &__check-icon {
    color: $light-green;
    margin: {
       right: 7px;
       left: -20px;
    }
  }
  &__error-icon {
    color: $light-red;
    margin: {
      right: 5px;
      left: -19px;
    }
  }
  &.expanded {
    .navbar__title, .navbar__listitem {
      white-space: normal;
    }
  }
}

.navigation__overlay {
  z-index: 900;
  @include breakpoint(medium) {
    z-index: 800;
  }
}
