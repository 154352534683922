/* General styling for custom tooltips */

.tooltip--custom {
    display: inline-block;
    position: relative;
    &__text {
        color: $primary-color;
        &:hover, &.active {
            text-decoration: underline;
            color: $primary-color;
        }
        .tooltip--custom__popup {
            top: 20px;
        }
    }
    &__info-icon {
        color: $medium-gray;
        font-size: 18px;
        position: relative;
        top: 1px;
        left: 0.35rem;
        cursor: pointer;
        &.active {
            color: $orange;
        }
        .tooltip--custom__popup {
            font-size: 80%;
            font-weight: normal;
        }
    }
    &__popup-wrap {
        position: relative;
        z-index: 406;
        display: none;
        cursor: auto;
    }
    &__popup {
        position: absolute;
        top: 5px;
        left: 0.15rem;
        background: #fafafa;
        border: solid 1px $light-gray;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
        padding: 1.3em 1.25em 1.25em;
        border-radius: 5px;
        line-height: 1.4;
        width: auto;
        min-width: 75vw;
        max-width: 500px;
        color: $body-font-color;
        font-family: $body-font-family;
        font-size: 90%;
        font-style: normal;
        white-space: normal;
        text-align: justify;
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: subpixel-antialiased;
        z-index: 405;
        @include breakpoint(medium) {
            min-width: 30vw;
        }
        @include breakpoint(large) {
            min-width: 20vw;
        }
        &.alert {
            background: $alert-color;
            color: $white;
            .close-button {
                color: $white;
            }
        }
        &--small {
            @extend .tooltip--custom__popup;
            padding: 0.25em 0.5em;
            min-width: auto;
        }
    }
    &__link.link--secondary {
        &.active {
            color: $primary-color;
            border-bottom: 1px solid $primary-color;
        }
        & + .tooltip--custom__popup {
            top: 35px;
        }
    }
    &__link.black {
        color: $black;
    }
}

/**
Styling for custom pop-ups
 */
.popup {
    @extend .callout;
    position: fixed;
    top: 70px;
    left: 3%;
    z-index: 1006;
    width: 94%;
    max-height: 80%;
    overflow: auto;
    @include breakpoint(medium) {
        top: 150px;
        left: 10%;
        width: 80%;
        max-height: 80%;
        margin-left: 15px;
    }
    @include breakpoint(large) {
        position: absolute;
        top: -2%;
        left: 2.5%;
        width: 95%;
        max-height: 100%;
        margin-left: 0px;
    }
    &__title {
        font-size: 1.5em;
        margin-bottom: 0.5em;
        font-weight: normal;
        display: block;
    }
    &__close-button {
        position: relative;
        text-align: right;
        @include breakpoint(medium) {
            float: right;
            right: -0.5em;
            top: 0;
        }
        .button {
            margin: 0;
        }
        .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
            position: relative;
            top: 0.05em;
            margin-right: 5px;
            font-size: $small-font-icon;
        }
        & + .popup__title, & + .reveal__title {
           @include breakpoint(medium) {
               padding-right: 85px;
           }
        }
    }
    &__overlay {
        z-index: 1005;
    }
    &__table-container, .button-group__table-container {
        overflow: auto;
        max-height: 250px;
        @include breakpoint(medium) {
            max-height: 300px;
        }
    }
    &__table-container {
        margin-top: 0.5em;
    }
}

/**
Overwriting Foundation defaults for modals
 */
.reveal {
    @extend .callout;
    height: auto;
    overflow: hidden;
    .row {
        margin-left: -.9375rem;
        margin-right: -.9375rem;
    }
    &:focus {
        outline: none;
    }
    &__title {
        @extend .popup__title;
    }
    .lead {
        font-size: 1.2em;
        line-height: 1.5;
    }
    &__close-button {
        @extend .popup__close-button;
    }
    &-modal-bg {
        @extend .popup__overlay;
    }
}
