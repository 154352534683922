#commissioner_resource_centre {
    position: relative;
    .section-button {
        @include breakpoint(medium) {
            position: absolute;
            top: -0.5em;
            right: 0.5em;
        }
    }
    .faq {
        &__navigation {
            text-align: center;
            width: auto;
            .columns {
                float: none;
                display: inline-block;
                vertical-align: top;
            }
            &__button {
                color: $extra-dark-gray;
                cursor: pointer;
                margin: 0.5em 0 1em;
                font-size: 1em;
                display: inline-block;
                &.active {
                    color: $primary-color;
                }
            }
            &__icon {
                display: block;
                .svg-inline--fa {
                    font-size: $large-font-icon;
                    margin-bottom: 5px;
                }
            }
        }
        &__section {
            td {
                padding: 0.6em 1em;
            }
            tr {
                background: transparent;
            }
            .button-group .button {
                margin-bottom: 0;
                font-weight: normal;
            }
            &__header {
                background: $white;
                td {
                    padding: 0.75em 1em 0.75em 0.75em;
                }
            }
            &__title {
                display: inline-block;
                font-size: 1.25em;
                margin-bottom: 0;
                color: $primary-color;
                .svg-inline--fa {
                    margin-right: 7px;
                }
            }
            &__question {
                cursor: pointer;
                background: transparent;
                border-top: 1px solid $panel-border;
                &:first-child {
                    border: 0;
                }
                td {
                    position: relative;
                }
                &-title {
                    font: {
                        size: 1em;
                        weight: bold;
                    }
                    display: inline-block;
                    padding-right: 40px;
                    margin-bottom: 0;
                    .svg-inline--fa {
                        font-size: $small-font-icon;
                        color: $dark-gray;
                        margin-right: 10px;
                    }
                    & + .svg-inline--fa {
                        position: absolute;
                        right: 0.75em;
                        top: 0.6em;
                        color: $extra-dark-gray;
                    }
                }
                &.expanded {
                    background: #f8f8f8;
                }
            }
            &__answer {
                td {
                    padding: 0 1em 0.5em 2.5em;
                    background: #f8f8f8;
                }
            }
        }
    }
    .document-centre {
        &__document {
            text-align: center;
            position: relative;
            padding-bottom: 50px;
            &__icon {
                .svg-inline--fa {
                    font-size: $xxlarge-font-icon;
                    color: $primary-color;
                    margin-top: 5px;
                }
            }
            &__title {
                color: $primary-color;
                margin: 10px 0 1.75em 0;
            }
            &__text {
                font-size: $small-font-size;
                color: $extra-dark-gray;
            }
            &__button {
                width: 100%;
                position: absolute;
                bottom: -0.5em;
                left: 0;
                border-radius: 0 0 $global-radius $global-radius;
            }
        }
    }
}