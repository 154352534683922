#registration-multistep {
    .registration {
        &__navigation {
            text-align: center;
            @include breakpoint(medium) {
                margin-bottom: 15px;
            }
            &-item {
                position: relative;
                width: 20%;
                color: $extra-dark-gray;
                .circles {
                    position: absolute;
                    left: -15px;
                    top: 17px;
                    @include breakpoint(small only) {
                        left: -10px;
                        span:first-child {
                            display: none;
                        }
                    }
                    span {
                        display: inline-block;
                        width: 6px;
                        height: 6px;
                        background: $medium-gray;
                        border-radius: 50px;
                        margin: 0 2px;
                    }
                }
                &:first-child {
                    .circles {
                        display: none;
                    }
                }
                &__icon {
                    display: inline-block;
                    width: 100%;
                    .svg-inline--fa {
                        font-size: $medium-font-icon;
                        margin: auto;
                        &.fa-stack-2x {
                            color: $dark-gray;
                        }
                    }
                }
                &__title {
                    @include breakpoint(small only) {
                        display: none;
                    }
                }
                // default menu item/step
                &.default {
                    color: $extra-dark-gray;
                    .registration__navigation-item__icon {
                        .svg-inline--fa {
                            &.fa-stack-2x {
                                color: $dark-gray;
                            }
                        }
                    }
                }
                // current menu item/step
                &.active {
                    color: $primary-color;
                    .registration__navigation-item__icon {
                        .svg-inline--fa {
                            &.fa-stack-2x {
                                color: $primary-color;
                            }
                        }
                    }
                }
                &.invalid {
                    color: $alert-color;
                    .registration__navigation-item__icon {
                        .svg-inline--fa {
                            &.fa-stack-2x {
                                color: $alert-color;
                            }
                        }
                    }
                }
                &.warn {
                    color: $warning-color;
                    .registration__navigation-item__icon {
                        .svg-inline--fa {
                            &.fa-stack-2x {
                                color: $warning-color;
                            }
                        }
                    }
                }
                // previous/verified menu item/step
                &.verified {
                    color: $success-color;
                    cursor: pointer;
                    .registration__navigation-item__icon {
                        .svg-inline--fa {
                            &.fa-stack-2x {
                                color: $success-color;
                            }
                        }
                    }
                    .circles {
                        span {
                            background: $success-color;
                            cursor: default;
                        }
                    }
                }
                // not active menu item/step (all of the steps except the last one when registration was submitted)
                &.disabled {
                    color: $medium-gray;
                    .registration__navigation-item__icon {
                        .svg-inline--fa {
                            &.fa-stack-2x {
                                color: $medium-gray;
                            }
                        }
                    }
                }
            }
        }
        &__panel {
            max-width: 570px;
            position: relative;
            display: table;
            padding: {
                bottom: 70px;
                top: 4em;
            }
            height: 300px;
            @include breakpoint(small only) {
                margin-top: 5px;
            }
            @include breakpoint(medium) {
                height: 400px;
            }
            &__content {
                display: table-cell;
                vertical-align: middle;
                .tooltip--custom__popup {
                    padding: 0.75em 1em;
                    font-size: 0.7em;
                    .close-button {
                        top: 0;
                        right: 0.2em;
                    }
                }
            }
            &__title {
                color: $primary-color;
                margin: 0.5em 0 1.25em;
                padding-right: 65px;
                position: absolute;
                top: 0.75em;
                left: 1em;
                @include breakpoint(medium) {
                    left: 1.25em;
                }
            }
            &__icon {
                position: absolute;
                top: 1em;
                right: 0.75em;
                color: $medium-gray;
                @include breakpoint(medium) {
                    top: 1.5em;
                    right: 1.25em;
                }
                @include breakpoint(large) {
                    right: 1.5em;
                }
                .svg-inline--fa {
                    font-size: $xlarge-font-icon;
                }
            }
            .bottom-buttons {
                position: absolute;
                bottom: 1em;
                right: 0;
                padding: 0 1em;
                @include breakpoint(medium) {
                    padding: 0 1.5em;
                }
            }
            .link-previous {
                margin-top: 3em;
            }
            .next-button {
                float: right;
                margin-bottom: 0;
                @include breakpoint(medium) {
                    min-width: 150px;
                }
            }
            .form-error {
                margin: {
                    bottom: 0;
                    top: -0.75rem;
                }
                min-height: 18px;
            }
            .org-type {
                position: relative;
                top: 0.5em;
                margin-bottom: -50px;
                @include breakpoint(medium) {
                    top: 1.25em;
                    margin-bottom: -25px;
                }
                &__button {
                    width: 100%;
                    margin: 0 0 15px 0;
                    @include breakpoint(small down) {
                        min-height: 65px;
                        padding: 7px;
                    }
                }
            }
            a.org-type__button {
                @include breakpoint(small down) {
                    display: table;
                    height: 65px; // Firefox fix
                    span {
                        display: table-cell;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}