
#provider_registration {
  body {
    background: #f2f2f2;
  }

  .help-collapsible-icon {
    position: relative;
    top: 0;
    right: 0;
  }

  #pageTitle {
    transition: all 0.5s;
  }

  .columns--form {
    padding-left: 0;
  }

  [class*=column] + [class*=column]:last-child {
    float: left;
  }

  .columns--float-right {
    float: right !important;
  }

  table td {
    font-size: 15px !important;
  }

  a.button {
    font-size: 14px !important;
  }

  .mb-0 {
    margin-bottom: 0px !important;
  }

  label.error input, label.error select, label.error .custom-dropdown-button {
    border-color: #c60f13 !important;
  }

  .error small.error, small.error, span.error {
    font-size: 0.875rem;
  }

  .alert-custom {
    background: none;
    color: inherit;
    font-size: 0.875rem;
    border-left: 5px solid #134f8a;
    margin-top: 15px;
  }

  .cqc_help_text:hover {
    transition: color 0.3s;
    color: rgba(34, 34, 34, 0.75);
  }

  .contacts_table {
    width: 100%;
  }

  .contacts_table {
    width: 100%;
    word-break: break-word;
  }

  @media screen and (max-width: 800px) {
    .contacts_table td {
      display: block;
      border-bottom: 1px solid #eee;
      background: #fff;
    }
    .contacts_table td:before {
      content: attr(data-label) ":";
      float: left;
      font-weight: 700;
      margin-right: 10px;
    }
    .contacts_table td:last-child:before {
      content: "";
    }

    .contacts_table td:last-child {
      background: #f5f5f5;
    }

    .contacts_table td:last-child button {
      width: 100%;
      font-size: 15px;
    }

    .contacts_table td:last-child button:after {
      content: 'Delete';
      margin-left: 3px;
    }

    thead {
      display: none;
    }

    .contacts_table tr:nth-child(n):before {
      display: block;
      content: "Contact " attr(data-entry);
      background: #F5F5F5;
      padding: 10px;
    }
  }

  .cqc_action {
    display: inline-block;
  }

  .cqc_action_register_manually {
    float: right;
  }

  .cqc_action_register_manually button {
    margin-left: 10px;
  }

  @media screen and (max-width: 710px) {
    .cqc_action {
      display: block;
      text-align: center;
    }
    .cqc_action button {
      width: 100%;
    }
    .cqc_action_register_manually {
      float: initial;
    }
    .cqc_action_register_manually button {
      margin-left: 0px;
    }
  }

  .label {
    font-size: $form-label-font-size;
  }

  .form-error {
    font-size: $form-label-font-size;
    font-weight: normal;
  }

}
