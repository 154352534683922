/* CQC rating box */

.cqc {
    &-rating {
        strong {
            margin-right: 10px;
        }
        /* CQC status circle and star icons */
        .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
            color: $rating-notinspected;
            &.Outstanding {
                color: $rating-outstanding;
            }
            &.Good {
                color: $rating-good;
            }
            &.Requires {
                color: $rating-improvement;
            }
            &.Inadequate {
                color: $rating-inadequate;
            }
            &.Inadequate {
                color: $rating-inadequate;
            }
        }
    }
    &-rating-box {
        display: inline-block;
        padding: 5px 10px;
        color: $white;
        margin-top: 5px;
        border-radius: 0;
        text-align: center;
        &.button {
            border-radius: $global-radius;
        }
    }
    a &-rating-box {
        border-radius: $global-radius;
    }
    // Box colours based on CQC rating
    &-outstanding {
        background: $rating-outstanding;
    }
    &-good {
        background: $rating-good;
    }
    &-improvement {
        background: $rating-improvement;
    }
    &-inadequate {
        background: $rating-inadequate;
    }
    &-notinspected {
        background: $rating-notinspected;
    }
}

/* CQC rating table */

table.cqc-rating {
    min-width: 100%;
    margin-bottom: 0px;
    td.cqc-rating-column {
        background: $rating-notinspected;
        &.Outstanding {
            background: $rating-outstanding;
        }
        &.Good {
            background: $rating-good;
        }
        &.Requires {
            background: $rating-improvement;
        }
        &.Inadequate {
            background: $rating-inadequate;
        }
        &.Inadequate {
            background: $rating-inadequate;
        }
    }
}
.cqc-rating-div {
    background: $rating-inadequate;
    &.Outstanding {
        background: $rating-outstanding;
    }
    &.Good {
        background: $rating-good;
    }
    &.Requires {
        background: $rating-improvement;
    }
    &.Inadequate {
        background: $rating-inadequate;
    }
    &.Inadequate {
        background: $rating-inadequate;
    }
}