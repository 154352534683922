#provider_homepage {
  .media {
    &__body {
      display: block;
      font-size: $small-font-size;
      margin-left: 38px;
      padding-left: 12px;
      border-left: 1px solid $panel-border;
    }
    &__heading {
      margin-bottom: 5px;
    }
    &__link-container {
      display: inline-block;
      position: absolute;
      top: 1.2em;
      left: 1em;
    }
    &__link {
      .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
        font-size: $xlarge-font-icon;
      }
    }
  }
  .tile {
    &__body {
      display: block;
      margin-left: 45px;
      padding-left: 12px;
      border-left: 1px solid $panel-border;
    }
    &__title {
      font-weight: $global-weight-bold;
      margin-bottom: 1em;
      color: $primary-color;
      .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
        font-size: $medium-font-icon;
        margin-right: 7px;
        position: relative;
        top: 1px;
      }
    }
    .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
      position: relative;
      top: 3px;
      font-size: $large-font-icon;
      margin-right: 8px;
    }
  }

  .card {
    background: white;
    border: 1px solid $panel-box-border-color;
    padding: 35px 0;
  }

  .accordion {
    margin: 1em 0;
    .title {
      color: #2980b9;
      margin: 0;
      padding: 10px;
    }
    p {
      margin: 0;
    }
  }

  %header__info-box {
    text-align: center;
    padding: 0.5em;
    border-bottom: 1px solid $panel-box-border-color;
    width: 100%;
    @include breakpoint(medium) {
      padding: 1em 1.5em;
      text-align: left;
    }
    @include breakpoint(large) {
      border-right: 1px solid $panel-box-border-color;
      border-bottom: 0;
    }
  }

  .header {
    hr {
      margin-top: 1.5em;
      margin-bottom: 1em;
    }
    .main {
      text-align: center;
      @include breakpoint(medium) {
        text-align: right;
      }
    }

    &__top {
      padding: 0.75em 0.5em 0;
      font-weight: 700;
      @include breakpoint(medium) {
        padding: 0.75em 0;
      }
      h4 {
        padding: 0;
        margin: 0;
        @include breakpoint(medium) {
          padding: 10px 0;
        }
      }
    }

    &__back {
      padding: 0.5em;
      text-align: left;
      @include breakpoint(medium) {
        padding: 1em 0;
        text-align: right;
      }
      .link--icon {
        margin-top: 5px;
      }
    }

    &__rate {
      @extend %header__info-box;
      @include breakpoint(large) {
        width: 20%;
      }
    }

    &__email {
      @extend %header__info-box;
      @include breakpoint(medium) {
        width: 100%;
        border: 0;
        border-right: 1px solid $panel-box-border-color;
      }
      @include breakpoint(large) {
        width: 50%;
      }
    }

    &__phone {
      @extend %header__info-box;
      border: 0;
      @include breakpoint(medium) {
        width: 100%;
      }
      @include breakpoint(large) {
        width: 50%;
        border: 0;
      }
    }
    h2 {
      font-size: 2.25em;
      position: relative;
      top: -0.5rem;
    }
    h2, h3 {
      margin: 0;
      margin-top: 8px;
    }
    h3 {
      font-size: 1.5rem;
    }
  }
  #reporting_information {
    h4 {
      margin-top: 0.75em;
      margin-bottom: 0.75em;
      padding-bottom: 0.25em;
      border-bottom: 1px solid $panel-box-border-color;
    }
    .four-multi-column {
      @include breakpoint(small only) {
        column-count: 2;
      }
      li {
        list-style-type: none;
      }
    }
  }
  p {
    font-size: 1em;
  }

  #map {
    width: 100%;
    min-height: 600px;
    height: 95%;
    margin-top: 22px;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    z-index: 100;
    .ccg-labels {
      color: $white;
    }
      .legend {
          background: rgba(256,256,256,0.5);
          box-shadow: none;
          span {
              display: inline-block;
              margin-bottom: 5px;
          }
          i {
              width: 18px;
              height: 18px;
              float: left;
              margin-right: 8px;
              opacity: 0.7;
          }
      }
  }

  .info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }

  .info h4 {
    margin: 0 0 5px;
    color: #777;
  }

  .leaflet-tooltip.sector-labels {
    background-color: transparent;
    border: transparent;
    font-size: 40px;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
    vertical-align: middle;
  }

  .leaflet-tooltip.ccg-labels {
    background-color: transparent;
    border: transparent;
    box-shadow: none;
    font-size: 15px;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    vertical-align: middle;
    white-space: normal;
  }

  .home-icon__container {
    background: transparent;
    border: none;
    width: 40px;
    height: 40px;
  }

  .home-icon {
    font-size: 20px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-align: center;
  }

  .icon-label {
    font-size: 10px;
    color: white;
    text-shadow: 0 0 3px black;
  }

  .rt-resizable-header-content {
    white-space: normal;
  }

  .pd {
    &__table {
      margin-top: 15px;
      width: 100%;
    }
  }

  .leaflet-bar a {
    width: auto;
    padding: 0 10px;
  }

  .divider {
    margin-top: 5px;
    border-top: 1px solid hsla(0, 0%, 100%, 0.6);
    width: 95%;
    margin-left: 2.5%;
  }

  .spinner {
    z-index: 1001;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.7);
    text-align: center;

    &__inner {
      color: rgba(0, 0, 0, 0.7);
      font-size: 500%;
      position: relative;
      left: 0;
      top: 45%;
    }
  }

  // nursing home information box
  .info-box {
    margin: 25px auto 15px auto;
    margin-top: 5px;
    background: white;
    &__title {
      margin-top: 20px;
      margin-bottom: 18px;
      color: $blue-title;
      font-size: 1.4rem;
      &--underlined {
        border-bottom: 1px solid #ddd;
      }
    }


    &__punctuality {
      display: inline-block;
      padding: 5px 10px;
      color: $light-text;
      margin: {
        top: 5px;
        right: 5px;
      }
    }

    @media print, screen and (max-width: 1023px) {
      .medium-12 {
        border-right: none;
        border-bottom: 1px solid #ccc;
        padding-bottom: 30px;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .datepicker {
    width: 150px;
    margin: 0;
    line-height: 90%;
    height: 90%;
    float: right;
    top: 5px;
    position: relative;
    margin-right: 10%;
    @include breakpoint(medium) {
      margin-right: 6%;
    }
  }
  .link--icon {
    display: inline-block;
    margin-bottom: 5px;
  }
  #specialist-equipment, #continence-supply {
    h5 {
      margin-bottom: 1em;
    }
  }
  .tables table {
    width: 100%;
    td {
      padding: 5px 15px;
      border: 1px solid $medium-gray;
    }
  }
  .accordion__body {
    animation: none;
  }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: left;
    border:none;
  }

  .announcements-panel {

    padding: 0;
    padding-top: 5px;
    padding-bottom: 5px;

    &__heading {
      padding: 15px 30px;
      padding-bottom: 7px;
      border-bottom: 1px solid $medium-gray;

      &__option {
        display: inline-block;

        &__text {
          margin-right: 10px;
        }

        &:hover {
          cursor: pointer;
        }
        &:last-child {
          float: right;
        }
      }
    }

    &__contents {
      min-height: 400px;
      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      }
      overflow: scroll;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 7px;
    }

    &__announcement {
      padding: 10px;
    }
  }

  .vacancy_reporting {
    &__save {
      text-align: center;
    }
    &__loader {
      margin-left: 10px;
    }
  }

  .tickbox_container {
    margin-top: 20px;
    .tickbox {
      display: inline-block;
      text-align: center;
      width: 45px;

      .icon {
        border-radius: 50%;
        padding: 8px;
        background: #eee;
        color: #eee;
        font-size: 30px;
        &__checked {
          background: $green;
        }
      }

      @include breakpoint(small) {
        width: 70px;

        .icon {
          font-size: 38px;
        }
      }

      @include breakpoint(medium) {
        width: 37px;
        .icon {
          font-size: 28px;
        }
      }

      @include breakpoint(large) {
        width: 45px;
        .icon {
          font-size: 32px;
        }
      }

      @include breakpoint(xlarge) {
        width: 50px;
      }

      @include breakpoint(xxlarge) {
        width: 60px;
        .icon {
          font-size: 38px;
        }
      }

      @include breakpoint(xxxlarge) {
        width: 70px;
      }

      &__text{
        margin-top: 15px;
      }
    }
  }

  .filters:last-child {
    [class*=column]+[class*=column]:last-child {
      float: left;
    }
  }

  .outstanding_reports {
    margin-top: 20px;

    &__button {
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
    }
    &__column-button {
      width: 180px;
    }
  }

  .button--no-margin {
    margin: 0;
  }

  .warning_icon {
    background: $red;
    padding: 9px 11px 9px 14px;
    border-radius: 50%;
    color: #fff;
    margin-right: 10px;
  }

  .monthly_reporting {
    &__outstanding_reports_text {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .secondary-navigation {

    &__page_banner {
      background: rgb(211, 211, 211);
      position: relative;
      top: -25px;
      width: 1000%;
      font-size: 17px;
      padding-left: 44px;
      height: 33px;
      padding-top: 3px;
    }

    &__status-icon {
      margin-left: 10px;
      height: 30px;
    }
  }
}

