#placement-dashboards {
  #content-wrap {
    margin-top: 1.25em;
  }
  #mainContainer {
    width: 100%;
  }

  #map {
    width: 100%;
    height: 100%;
    min-height: 600px;
    border: 1px solid #ddd;
    z-index: 1;
  }

  .rt-resizable-header {
    background: hsl(0, 0%, 97%)
  }

  #charts {
    padding: 0;
    @include breakpoint(large) {
      padding-left: 15px;
    }
  }

  .callout--map {
    margin: 0;
    margin-bottom: 20px;
    @include breakpoint(large) {
      margin-bottom: 0;
    }
  }

  .info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  .info h4 {
    margin: 0 0 5px;
    color: #777;
  }

  .reset-button {
    font-size: 16px;
  }

  .reset-button a {
    line-height: 40px;
    width: 140px;
    height: 40px;
    display: none;
  }

  .info-box {
    &__title {
      color: $blue-title;
      font-size: 1.4rem;
    }
    &__date {
      display: block;
      position: relative;
      margin-top: 0;
      color: hsl(0, 0%, 50%);
      @include breakpoint(medium) {
        position: absolute;
        top: 0.25em;
        right: 1em;
      }
    }
  }

  .missing-data {
    background: white;
    border-radius: $global-radius;
    border: 1px solid $light-gray;
    padding: 15px;
    margin-top: 20px;
  }

  .map-info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    //background: rgba(255, 255, 255, 0.8);
    //box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 0.2);
  }

  .map-info h4 {
    margin: 0 0 5px;
    color: #777;
  }

  .leaflet-tooltip.sector-labels {
    background-color: transparent;
    border: transparent;
    font-size: 40px;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
    vertical-align: middle;
  }

  .leaflet-tooltip.ccg-labels {
    background-color: transparent;
    border: transparent;
    box-shadow: none;
    font-size: 15px;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    vertical-align: middle;
    white-space: normal;
  }

  .home-icon__container {
    text-align: center;
    background: transparent;
    border: none;
    width: 60px;
    height: 60px;
  }

  .home-icon {
    font-size: 20px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-align: center;
  }

  .icon-label {
    font-size: 10px;
    color: white;
    text-shadow: 0 0 3px black;
  }

  .sort {
    margin-left: 5px;
    font-size: 16px;
  }

  .pd {
    &__table {
      margin-top: 15px;
      width: 100%;
    }
  }
  .leaflet-bar a {
    width: auto;
    padding: 0 10px;
  }
  .dropdown {
    width: 100%;
    margin-left: 0;
    min-height: 50px;
    box-shadow: 0 6px 8px 0 hsla(0, 0%, 0%, 0.2);
    background: hsl(0, 0%, 95%);
    overflow: hidden;
    @include breakpoint(medium) {
      width: 50%;
      margin-left: 7.5%;
    }

    &__list {
      width: 100%;
      &__bottom {
        width: 100%;
        background: hsl(0, 0%, 90%);
        padding: 10px;
        @include breakpoint(medium) {
          padding: 10px 0;
        }
      }

      &__top {
        background: hsl(0, 0%, 95%);
      }
      &--active {
        font-weight: 700;
        background: hsl(0, 0%, 90%);
        border-left: 3px solid $primary-color !important;
      }
    }

    &__sector {
      width: 100%;
      height: 50%;
      list-style: none;
      text-align: center;
      margin: 0;
      & li {
        display: inline-block;
        text-align: center;
        cursor: pointer;
        padding: 10px 0;
        border-left: 3px solid hsl(0, 0%, 95%);
        width: calc(100% / 5);
        color: hsl(0, 0%, 17%);
        &:hover {
          font-weight: 700;
          border-left: 3px solid $primary-color;
          background: hsla(0, 0%, 0%, 0.05)
        }
      }

    }

    &__ccg {
      width: 100%;
      height: 50%;
      margin: 0;
      list-style: none;
      text-align: left;
      & li {
        display: inline-block;
        text-align: center;
        cursor: pointer;
        border-left: 3px solid hsl(0, 0%, 90%);
        width: calc(100% / 3);
        max-width: calc(100% / 3);
        color: hsl(0, 0%, 40%);
        &:hover {
          font-weight: 700;
          border-left: 3px solid $primary-color;
          background: hsla(0, 0%, 0%, 0.05)
        }
      }
    }
  }

  .divider {
    margin-top: 5px;
    border-top: 1px solid hsla(0, 0%, 100%, 0.6);
    width: 95%;
    margin-left: 2.5%;
  }

  .datepicker {
    width: 130px;
    margin: 0;
    line-height: 100%;
    height: 22px;
    float: right;
    position: relative;
    margin-right: 10%;
    padding: 1px;
    background-color: inherit;
    background-position: right;
    border: none;

    @include breakpoint(medium) {
      margin-right: 6%;
    }
  }
  .legend {
    line-height: 18px;
    color: #555;
    background: white;
    box-shadow: none;
    border: 2px solid rgba(0, 0, 0, 0.2);
  }
  .legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
  }

  .pagination-bottom {
    border-top: 1px solid $light-gray;
    background: $body-background;
    border: 1px solid $body-background;
    width: 102%;
    position: relative;
    left: -1%;
    top: 5px;
    text-align: center;
  }

  // restyling react tables
  .ReactTable {
    border: 0;
    border-radius: $global-radius;
    .rt-thead.-header {
      border: 1px solid $light-gray;
      border-radius: $global-radius $global-radius 0 0;
    }
    .rt-tbody {
      border: 1px solid $light-gray;
      border-top: 0;
      border-radius: 0 0 $global-radius $global-radius;
      .rt-tr-group {
        border: none;
        &:last-child .rt-tr {
          border-radius: 0 0 $global-radius $global-radius;
        }
      }
    }
  }

  .secondary-navigation {
    &__path__button {
      @include breakpoint(medium down) {
        padding: 0.8rem 1.5rem 0.806rem;
        font-size: 1rem;
        margin-top: 0.25em;
      }
    }
    .custom-list__selected {
      border: 1px solid rgb(179, 179, 179);
    }
    .secondary-navigation__path-name {
      float: none;
    }
    @include breakpoint(medium) {
      .custom-list {
        display: inline-block;
        margin-right: 1em;
      }
      .custom-list__select {
        margin: 0;
      }
    }
    @include breakpoint(large) {
      .secondary-navigation__path-name {
        float: left;
      }
      .custom-list {
        padding-left: 0;
        display: inline-block;
        float: right;
        margin-right: 0;
      }
      .custom-list__select {
        margin: 0;
        position: relative;
        top: -2px;
        right: -0.5em;
      }
    }
  }
  .tooltip--custom__popup-wrap {
    z-index: 806;
  }
  .tooltip--custom__popup {
    width: auto;
    min-width: auto;
    white-space: nowrap;
    z-index: 806;
  }
}