/* Base variables and styling */

$tiny-font-icon: 13px;
$small-font-icon: 16px;
$medium-font-icon: 20px;
$large-font-icon: 26px;
$xlarge-font-icon: 38px;
$xxlarge-font-icon: 44px;

/**
General styling
*/
body {
    z-index: 1;
    &.is-reveal-open {
        overflow: visible !important;
    }
}

#body {
    padding-bottom: 2em;
}

#container {
    min-height: 100%;
    position: relative;
    overflow: hidden;
    padding-bottom: 115px;
}

.breadcrumbs {
    border-style: none;
    background-color: transparent;
    margin-top: 1em;
    margin-bottom: 0.3rem;
    padding-left: 0;
}

#page_subtitle_box {
    padding: 0 20px;
    border-radius: $global-radius;
    h3 {
        font-size: 1.4rem;
        margin: 0;
        padding: 1rem 0;
        color: $blue-title;
        border-bottom: 1px solid $medium-gray;
        @include breakpoint(medium) {
            font-size: 1.4rem;
        }
        &.subtitle_member {
            color: $body-font-color;
        }
    }
}

/**
For small screens
*/
.non-retina-image {
    display: inline-block;
}
.retina-image {
    display: none;
}

/**
For high resolution/retina screens
*/
@include breakpoint(retina) {
    .non-retina-image {
        display: none;
    }
    .retina-image {
        display: inline-block;
    }
}

/**
Styling for white box behind the content
*/
.callout {
    border-radius: $global-radius;
    padding: 1em 1em;
    @include breakpoint(medium) {
        padding: 1em 1.5em;
    }
    &__callout-box {
        &--border-top {
            border-top: 1px solid $panel-box-border-color;
            padding-top: 1em;
        }
        &__title {
            display: inline-block;
            margin: 0 0 0.75em;
        }
    }
    &--closeable {
        padding-right: 1.7em;
    }
}

/**
Panel styling
*/
.panel {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
}
.panel-box-container {
    overflow: hidden;
    p {
        margin: 0;
        margin-top: 5px;
    }
}

.panel-box {
    margin: 1rem 0;
    padding: 0 20px;
    .panel {
        border-top: 1px solid $medium-gray;
        padding-top: 1.5rem;
    }
    &-column-medium {
        margin: 10px 0;
        padding: 0 10px;
        @include breakpoint(medium) {
            border-right: 1px solid $medium-gray;
            margin: 1.5rem 0;
            padding: 0 15px;
        }
        @include breakpoint(large) {
            padding: 0 20px;
        }
    }
    &-column-large {
        margin: 0.75rem 0;
        padding: 0 10px;
        @include breakpoint(large) {
            border-right: 1px solid $medium-gray;
            margin: 1.5rem 0;
            padding: 0 20px;
        }
    }
}
.border-corrigation-medium {
    @include breakpoint(medium) {
        border-right: 0;
    }
    @include breakpoint(large) {
        border-right: 1px solid $medium-gray;
    }
}
.border-corrigation-large {
    @include breakpoint(large) {
        border-right: 0;
    }
}

/**
temporary class name, can be removed after Foundation 5 upgrade is complete
*/
.content-box {
    @extend .callout;
}

.close {
    @extend .close-button;
}
.small {
    font-size: $small-font-size;
}

ul {
    .no-bullets {
        list-style: none;
        margin-left: 0;
        margin-top: 0.5em;
    }
    .four-multi-column {
        column-count: 4;
        column-gap: 20px;
    }
    .two-multi-column {
        column-count: 2;
        column-gap: 25px;
    }
    .three-multi-column {
        column-count: 3;
        column-gap: 20px;
    }
}

/**
Background colour styling
*/
.success {
    background: $success-color;
    &--text {
        background: transparent;
        color: $success-color !important;
    }
}
.alert {
    background: $alert-color;
    &--text {
        background: transparent;
        color: $alert-color !important;
    }
}
.warning {
    background: $warning-color;
    &--text {
        background: transparent;
        color: $warning-color !important;
    }
}

/**
Icon styling
*/
.fa, .fas, .far, [class^="fi-"], .svg-inline--fa, .fa.medium, .fas.medium, .far.medium, [class^="fi-"].medium, .svg-inline--fa.medium {
    font-size: $medium-font-icon;
    margin-right: 5px;
}
.fa.small, .fas.small, .far.small, [class^="fi-"].small, .svg-inline--fa.small {
    font-size: $small-font-icon;
    margin-right: 5px;
}
.fa.large, .fas.large, .far.large, [class^="fi-"].large, .svg-inline--fa.large {
    font-size: $large-font-icon;
    margin-right: 5px;
}
.fa.extra-large, .fas.extra-large, .far.extra-large, [class^="fi-"].extra-large, .svg-inline--fa.extra-large {
    font-size: $xlarge-font-icon;
    margin-right: 5px;
}

.has-tip, .has-tip:hover {
    border: 0;
}

/**
Overlay styling
*/
.overlay, .popup__overlay, .navigation__overlay, .secondary-navigation__overlay {
    height: 100%;
    width: 100%;
    position: fixed; /* Stay in place */
    left: 0;
    top: 0;
    background-color: $overlay-background; /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    display: none;
}
.overlay {
    z-index: 400;
}

/**
Comments styling
*/
#comments-content {
    ul {
        font-size: .875rem;
    }
    .limit-message {
        text-align: center;
    }
}
.comment form, .ac-reason form {
    overflow: hidden;
    height: 0px;
}
.ac-reason {
    position: relative;
    form {
        position: absolute;
        top: 1rem;
        left: 0;
    }
}
.comment textarea, .ac-reason textarea {
    background: transparent;
    outline: none;
    overflow: hidden;
    resize: none;
    border: 1px solid #ccc;
    width: 95%;
    display: block;
    padding: 0.3rem;
    font-size: 0.875rem;
    margin: 0 0 0.5rem 0;
}
.hidden-comment {
    overflow: hidden;
    word-wrap: break-word;
    white-space: pre-wrap;
    height: 0px;
    padding: 0.3rem;
    width: 95%;
    color: transparent;
}
.comment-text, .acreason-text {
    overflow: hidden;
    word-wrap: break-word;
    white-space: pre-wrap;
    width: 95%;
}
.comment textarea, .comment div, .ac-reason textarea, .ac-reason div {
    box-sizing: content-box;
}
.comment div, .ac-reason div {
    word-wrap: break-word;
    white-space: pre-wrap;
}

.full-width {
    min-width: 100%;
}
.word-break {
    word-wrap: break-word;
}
.break_email {
    word-break: break-all;
}
.list_emails, .list_phones{
    min-width: 75px;
    word-break: break-all;
}

#inner-content {
    padding: 0.5em;
    @include breakpoint(medium) {
        padding: 1em 0.75em;
    }
    @include breakpoint(large) {
        padding: 1.5em 1em;
    }
}

#content-wrap {
    padding: {
        left: 0;
        right: 0;
    }
    max-width: 1500px;
    margin: {
        left: auto;
        right: auto;
        top: 1em;
    }
}

@include breakpoint(medium) {
    #content-wrap {
        position: relative;
        padding: {
            left: 0em;
            right: 0em;
        }
    }
    #outer-content-wrap {
        margin-left: 75px;
        padding: 0 1em 0 1.25em;
    }
}
@include breakpoint(large) {
    #content-wrap {
        position: relative;
        width: 100%;
        padding: {
            right: 0.5em;
            left: 0em;
        }
    }
    #outer-content-wrap {
        margin: {
            right: -1em;
            left: 200px;
        }
        padding: 0 1.5em;
        transition: all 0.5s;
    }
}

/**
Styling for Foundation 6 internal pages
*/
.internal-page {
    &__header {
        font-size: 1.25em;
        margin: 0.25em 0 0.5em 0;
        display: block;
        @include breakpoint(medium) {
            font-size: 1.75em;
        }
        & + .button-group {
            margin: 0;
            .button {
                margin-bottom: 0.5rem;
                @include breakpoint(medium) {
                    margin-bottom: 1rem;
                }
            }
        }
        & + .button {
            margin-top: 0;
        }
    }
    &__subheader {
        font-size: 1rem;
        font-style: italic;
        margin: -0.25em 0 1.5em 0;
        & + .button, & + .button-group {
            margin-top: 0.5em;
        }
    }
    &__section-title {
        font-size: 1.25em;
        font-weight: normal;
        margin: 0.75em 0 0.5em 0;
        & + .button, & + .button-group {
            margin: 0 0 1rem 0;
        }
        & + .button-group .button {
            margin-bottom: 0;
        }
    }
    &__fieldset-wrap {
        @extend .callout;
        overflow: hidden;
        margin-bottom: 0.5em;
        @include breakpoint(medium) {
            margin-bottom: 1em;
        }
        .fieldset {
            border: 0;
            padding: 0;
            legend {
                margin-bottom: 0.5em;
                &.collapsed {
                    margin-bottom: 0;
                }
            }
            .button {
                margin: 0.5em 0;
            }
        }
        & > .columns {
            width: 100%;
        }
    }
}

/**
Collapsible box styling
*/
.collapsible-box {
    padding: 0.75rem 1.5rem 0 1.5rem;
    margin: 0.5rem 0;
}

.collapsible-panel-header {
    position: relative;
    padding: 0.5rem 0 0.3rem 0;
    cursor: pointer;
}

.collapsible-panel-header strong {
    display: block;
    padding-right: 25px;
}

.collapsible-panel {
    padding-bottom: 1rem;
}

/**
Filter box styling
*/
.collapsible-icon {
    position: absolute;
    right: 0;
    top: 0.75rem;
}

#declaration-box {
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 5px;
    padding: 0.75rem 1rem 0 1rem;
    background-color: rgba(255,255,255,0.5);
    margin: 1rem 0;
    @include breakpoint(medium) {
        padding: 0.75rem 1.5rem;
    }
    p {
        font-size: 0.875em;
        color: #4d4d4d;
        margin: 0.5rem 0;
        font-style: italic;
    }
    strong {
        font-size: 0.875em;
        color: #4d4d4d;
        display: block;
        margin-bottom: 0;
    }
}

/**
Styling for announcement box
*/
.announcement {
    float: right;
}

/**
Styling for info/alert box
*/
.alert-box {
    font-weight: 400;
    margin-bottom: 1rem;
    position: relative;
    padding: 1rem;
    font-size: $small-font-size;
    transition: opacity .3s ease-out;
    background-color: scale-color($light-blue, $lightness: +65%);
    color: $body-font-color;
    border-radius: $global-radius;
    @include breakpoint(medium) {
        padding: 1rem 1.5rem;
    }
    a {
        text-decoration: underline;
        color: $body-font-color;
    }
    &.success {
        //background: scale-color($success-color, $lightness: +15%);
        background: $success-color;
        color: $white;
        .close-button, input[type='submit'] {
            color: $white;
        }
        a {
            color: $white;
        }
    }
    &.warning {
        background: scale-color($warning-color, $lightness: +35%);
        color: $black;
        .close-button, input[type='submit'] {
            color: $black;
             &:hover {
                color: $black;
            }
        }
        a {
            color: $black;
        }
    }
    &.alert {
        background: $alert-color;
        color: $white;
        .close-button, input[type='submit'] {
            color: $white;
            &:hover {
                color: $white;
            }
        }
        a {
            color: $white;
        }
    }
    &__header, h1 {
        font-size: 135%;
        margin: 0 0 0.25em 0;
    }
    .lead {
        margin-bottom: 0.5em;
        margin-top: -0.25em;
        font-size: 120%;
    }
    &__text {
        margin-bottom: 0;
    }
    .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
        position: relative;
        top: 2px;
    }
    .close-button, input[type='submit'] {
        border: 0 none;
        cursor: pointer;
        border-radius: 5px;
        color: $extra-dark-gray;
        float: right;
        vertical-align: center;
        padding: 0.25em 0.5em;
        background: transparent;
        font-size: 135%;
        position: absolute;
        top: 0;
        right: 0;
        &:hover {
            color: $body-font-color;
        }
    }
}

.alert-custom {
    background: none;
    color: inherit;
    font-size: $small-font-size;
    border: 1px solid $darker-main-blue;
    border-left: 5px solid $darker-main-blue;
    margin: {
        top: 15px;
        bottom: 15px;
    }
    padding: 0.5em;
    border-radius: 0;
}

.floating__error {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1005;
    @include breakpoint(large) {
        z-index: 706;
    }
    .alert-box {
        border-radius: 0;
        margin: 0;
    }
}

#glossaryContent {
    font-size: 90%;
    h6 {
        font-size: 100%;
    }
}

/**
Styling for custom drop-down select field
*/
.custom-list {
    display: block;
    float: none;
    position: relative;
    @include breakpoint(large) {
        display: inline-block;
        float: right;
    }
    span {
        display: inline-block;
        margin-right: 10px;
    }
    &__select {
        position: relative;
        display: inline-block;
        margin: 3px 0 3px 0;
        text-align: left;
    }
    &__selected {
        min-width: 210px;
        margin: 0;
        border-radius: 5px;
        height: 100%;
        padding: 0.6em 25px 0.6em 0.5em;
        border: 1px solid #ddd;
        background: #fafafa;
        font-size: 0.9em;
        position: relative;
        display: inline-block;
        cursor: default;
        color: #333;
        @include breakpoint(medium) {
            min-width: 250px;
        }
        @include breakpoint(large) {
            padding: 0.2em 25px 0.2em 0.5em;
        }
        &:hover {
            background: #fff;
        }
        &--light {
            background: #fff;
        }
        .fa {
            position: absolute;
            right: 5px;
            top: 0.9em;
            @include breakpoint(large) {
                top: 0.4em;
            }
        }
    }
    &__dropdown {
        width: 100%;
        margin: 0;
        border-radius: 5px;
        padding: 0.3em 0;
        border: 1px solid #ddd;
        background: #fff;
        font-size: 0.9em;
        box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.2);
        position: absolute;
        left: 0;
        top: 2.75em;
        z-index: 405;
        display: none;
        height: auto;
        max-height: 300px;
        overflow: auto;
        @include breakpoint(large) {
            top: 1.95em;
        }
    }
    &__items {
        padding: 0.5em 0.5em;
        cursor: default;
        @include breakpoint(large) {
            padding: 0 0.5em;
        }
        &:hover {
            background: $extra-light-gray;
        }
    }
}

.btn-box, .btn-box2 {
    text-align: center;
}
.counter-box {
    text-align: center;
    font-size: .875rem;
}
.punctuality-div {
    &__main {
        width: 100%;
        display: flex;
    }
    &__month {
        width: 33.3%;
        height: 35px;
        float:left;
        color: #fff;
        padding: 5px;
        border: 1px solid $body-background;
        text-align: center;
    }
}
#contacts-info li.cardinality {
    display: none;
}
div.button-holder .margin-10 {
    margin: {
        top: 10px;
        bottom: 20px;
    }
}
#id_submitted, #id_all_nursing_homes, #id_all_nursing_homes_1, #id_all_service_locations, #id_all_service_locations_1 {
    list-style: none;
    margin-left: 0;
}
.ui-slider {
    .ui-slider-range {
        z-index: 0;
    }
    .ui-slider-handle {
        z-index: 1;
    }
}