#carepulse_statistics {
  .vacancy-map {
    padding: 0.3rem;
    padding-top: 0;
    @include breakpoint(medium) {
      padding: 1rem;
      padding-top: 0;
    }
    .chart__title {
      margin: 0 0.5rem 1rem;
    }
    .row {
      margin: 0;
    }
  }

  .map-box {
    float: left;
    display: inline-block;
    width: 11.9%;
    margin: 0.3%;
    text-align: center;
    position: relative;
    border: 1px solid #ddd;
    line-height: 1;
    padding: 0.3%;
    @include breakpoint(medium) {
      width: 11.5%;
      margin: 0.5%;
      padding: 0.5%;
    }
    &.blank-box {
      border: none;
      background: transparent;
    }
    strong {
      display: inline-block;
      width: 100%;
      position: relative;
      top: -3px;
      text-align: left;
      font-size: 11px;
      @include breakpoint(medium) {
        font-size: 15px;
        top: 0;
      }
      @include breakpoint(large) {
        font-size: 13px;
      }
    }
    span {
      display: inline-block;
      text-align: center;
      width: 100%;
      position: absolute;
      top: 50%;
      right: 0%;
      font: {
        weight: normal;
        size: 12px;
      }
      @include breakpoint(medium) {
        font-size: 18px;
        top: 52%;
      }
      @include breakpoint(large) {
        font-size: 15px;
      }
    }
  }

  .filter-panel {
    h4 {
      padding: 0;
      position: relative;
      font: {
        size: 100%;
        weight: bold;
      }
      margin: 1.5rem 0 0.15rem;
    }
    &.chart__box {
      h4 {
        margin: 1rem 0 0.5rem;
      }
      span {
        display: block;
        @include breakpoint(medium) {
          display: inline-block;
        }
      }
      input {
        margin-right: 0.5rem;
      }
    }
  }

  @include breakpoint(small down) {
    .columns {
      padding: {
        left: 0.75rem;
        right: 0.75rem;
      }
    }
  }

  #ccg-toggle-container {
    padding: 0.5rem 1.5rem;
    position: relative;
    z-index: 2;
  }

  #vacancy-toggle-container {
    margin-top: -0.3rem;
    padding: 5px;
  }

  .tooltip--custom__popup--small {
    top: 0.5em !important;
  }

  .su-tooltip-box1, .su-tooltip-box2 {
    position: absolute;
    width: 0.7em;
    height: 0.7em;
    left: 15%;
    top: 0.2em;
    transform: rotate(45deg);
    @include breakpoint(small only) {
      left: 15%;
      top: 0.2em;
    }
  }

  .su-tooltip-box1 {
    background: #fafafa;
    z-index: 408;
  }

  .su-tooltip-box2 {
    box-shadow: -4px -4px 6px rgba(0, 0, 0, 0.2);
    z-index: 407;
  }

}