/* Footer */

.footer {
    z-index: 805;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top: 40px;
    background-color: $primary-color;
    padding: 0.8rem 0.5rem 0.65rem;
    text-align: center;
    color: $light-text;
    font-size: 0.8em;
    @include breakpoint(medium) {
        padding: 1rem 1rem 0.85rem;
        z-index: 905;
    }
    @include breakpoint(large) {
        text-align: left;
        padding: 0.8rem 2rem 0.7rem;
        & > div {
            //padding-right: 200px;
        }
    }
    &__text {
        margin: 0;
        font-weight: 400;
        color: $light-text;
        font-size: 1em;
    }
    &__link-list {
        display: inline-block;
        margin-top: 5px;
        font-size: 1em;
        @include breakpoint(large) {
            position: absolute;
            right: 2em;
            top: 0.95em;
            margin-top: 0px;
        }
    }
    &__second-sentence {
        display: block;
        margin: 0;
        @include breakpoint(medium) {
            display: inline-block;
        }
    }
    &__link {
        font-weight: 400;
        color: $light-text;
        text-decoration: underline;
        margin-right: 5px;
        margin-left: 5px;
        @include breakpoint(large) {
            margin: {
                left: 10px;
                right: 0px;
            }
        }
    }
}

.feedback {
    &__feedback-container {
        width: 100%;
        padding: 0;
    }
    &__feedback-submit {
        text-align: center;
        button {
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
}

.floating-button {
    position: fixed;
    bottom: 0;
    right: 50px;
    background-color: $navigation-background;
    padding: 5px 15px 5px 15px;
    text-decoration: none;
}

.footer__maintenance_notice {
    background: #A2000B;
    color: #eee;
    position: fixed;
    right: 141px;
    bottom: 0px;
    padding: 5px 15px 5px 15px;
    font-size: 13px;
}

.footer__maintenance_notice--margin {
    bottom: 45px;
}