#privacy-policy {
    h5 {
        margin-top: 20px;
        margin-bottom: 0 !important;
    }
    p {
        margin-bottom: 10px;
        margin-top: 10px;
        text-align: justify;
    }
    li {
        text-align: justify;
    }
    table tbody tr td, table tbody tr th, table tfoot tr td, table tfoot tr th, table thead tr th, table tr td {
        font-size: 1rem;
        font-weight: initial;
    }
    table tr td, table tr th {
        font-size: 1rem;
    }
    ol {
        list-style-type: lower-alpha;
    }
    #content-wrap {
        padding: 0 1em 0 0.25em;
    }
}