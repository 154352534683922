#cost_model_homepage {
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .template {
        &__title-box {
            overflow: visible;
            position: relative;
            min-height: 60px;
        }
        &__deadline {
            font-size: 100%;
            width: auto;
            padding: 0.35em 0.75em;
            text-align: center;
            margin: 0;
            @include breakpoint(large) {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
        &__title {
            font-size: 1.25em;
            color: $blue-title;
            padding: 0.5em 0;
            display: inline-block;
            @include breakpoint(large) {
                padding: 0.75em 280px 0.5em 0;
            }
        }
        &__homepage {
            padding: {
                top: 0;
                bottom: 0;
            }
        }
        &__start-page {
            .button-box {
                text-align: center;
                .button {
                   margin: 0.75em 0;
                }
            }
        }
        &__section {
            margin: 0 -1.5em;
            @include breakpoint(small only) {
                margin: 0 -1em;
            }
            padding: 0.75em 1.5em;
            border-bottom: 1px solid $light-gray;
            &:last-child {
                border-bottom: 0;
            }
            &__title {
                color: $blue-title;
                padding: 0.25em 0 0 0;
                cursor: pointer;
                h5 {
                    margin-bottom: 0.25em;
                }
                .columns:last-child {
                    text-align: right;
                }
            }
            &__title-icon {
                min-width: 18px;
            }
            &__row {
                border-radius: $global-radius;
                padding: {
                    top: 0.5em;
                    bottom: 0.5em;
                }
                margin: {
                    top: 0.5em;
                    bottom: 0.5em;
                    left: 0;
                    right: 0;
                }
                border: 1px solid $light-gray;
                .columns.button-container {
                    @include breakpoint(small only) {
                        margin-top: 1em;
                        text-align: center;
                    }
                    text-align: right;
                    padding-top: 0.25em;
                }
                .column.carehome-container {
                    padding-top: 0.25em;
                }
                .columns.label-container {
                    padding-top: 0.25em;
                    @include breakpoint(medium down) {
                        padding-top: 0.5em;
                    }
                }
                .button {
                    margin: 0;
                }
                .users-box {
                    margin-top: 5px;
                }
                .edited-box {
                    text-align: right;
                    margin-top: 5px;
                }
            }
            &--inactive {
                @extend .template__section;
                background: $inactive-background;
                color: $inactive-text;
                .template__section__row {
                    background: transparent;
                }
                .template__section__title {
                    color: $inactive-text;
                }
                .progress-bar__bar {
                    opacity: 0.5;
                }
                .label.secondary {
                    color: $inactive-text;
                }
            }
            .toggle-title {
                cursor: pointer;
                display: inline-block;
                margin-top: 5px;
            }
            .progress-bar {
                margin-top: 0.5em;
            }
        }
    }
    .navbar {
        display: none;
        overflow-y: auto;
        @include breakpoint(medium) {
            display: block;
            width: 200px !important;
            position: fixed !important;
            top: 0;
            left: 0;
            z-index: 505;
            padding-top: 140px;
        }
        @include breakpoint(large) {
            padding-top: 0;
            z-index: 805;
        }
        &__header {
            cursor: default;
            span {
                display: inline-block !important;
            }
        }
        &__box {
            display: block;
        }
        &__link {
            white-space: normal;
            max-height: none;
            padding: {
                left: 37px;
                top: 0.5em;
                bottom: 0.5em;
            }
            &--home {
                padding: {
                    left: 21px;
                    top: 0.85em;
                    bottom: 0.85em;
                }
            }
        }
        &__link-text {
            display: inline-block !important;
        }
        &__listitem {
            .navbar__icon--right {
                top: 0.7em;
                cursor: pointer;
            }
        }
    }
    .provider-request {
        margin: 0;
        .edit-carehome {
            @include breakpoint(large) {
                &__title {
                    padding-right: 175px;
                    display: block;
                    margin-bottom: 0.75em;
                }
                &__buttons {
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin-top: 0;
                }
            }
        }
    }
    .provider-name {
        margin-top: 0;
    }
    .cross {
        color: $light-red;
        cursor: pointer;
        float: right;
    }
}