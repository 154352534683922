#central_contacts_page {
    .monthly-reporting {
        div {
            width: 100%;
            max-width: 150px;
            white-space: nowrap;
        }
    }
    .vacancy-updates {
        width: 17%;
        min-width: 250px;
        .vacancy {
            position: relative;
            padding-right: 65px;
            margin-bottom: 12px;
            margin-top: 5px;
            .label {
                position: absolute;
                top: -3px;
                right: 0;
            }
        }
    }
}