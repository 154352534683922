/* Navigation for internal pages */

$additional_intnav_breakpoint: 1070px;

.internal-navigation__container {
    font-size: $small-font-size;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 905;
    background: $black;
    ul {
        margin: 0;
    }
    a, .accordion-menu a {
        color: $white;
        @include breakpoint(medium) {
            line-height: 1.5;
            padding: 6px 15px;
            color: $primary-color;
            background: $dropdown-background;
            white-space: normal;
            &:hover {
                background: $extra-light-blue;
                color: $medium-blue;
            }
        }
        &.internal-navigation__title {
            @include breakpoint(medium) {
                font-weight: bold;
                padding-top: 0;
                padding-bottom: 1em;
                color: $extra-dark-gray;
                &:hover {
                    background: $dropdown-background;
                    color: $extra-dark-gray;
                    cursor: default;
                }
            }
        }
    }
    .title-bar {
        width: auto;
        text-align: right;
        padding: 1em;
        &-title {
            text-transform: uppercase;
            margin-right: 5px;
            margin-top: 3px;
        }
        button {
            background: transparent;
            cursor: pointer;
        }
        .menu-icon {
            background: transparent;
        }
    }
    .top-bar {
        &-right {
            display: none;
            @include breakpoint(medium) {
                display: block;
            }
            .internal-navigation__dropdown.column1 {
                @include breakpoint(medium) {
                    right: 0;
                    left: auto;
                    width: 100% !important;
                    position: absolute;
                }
            }
        }
        .menu {
            & > li.internal-navigation__first-level {
                @include breakpoint(medium) {
                    float: left;
                }
            }
        }
    }

    .drilldown .is-drilldown-submenu-parent>a::after {
        visibility: visible;
        @include breakpoint(medium) {
            visibility: hidden;
        }
    }

    .internal-navigation {
        &__page-title {
            float: left;
            padding: 1em 1em 0.25em 1em;
            @include breakpoint(medium) {
                padding: 0.75em 0.25em;
            }
            @media screen and #{breakpoint(medium)} and #{breakpoint($additional_intnav_breakpoint down)} {
                float: none;
                border-bottom: 1px solid $extra-dark-gray;
            }
            h1 {
                font-size: 130%;
                margin: 0;
            }
            a, &:hover {
                color: $white;
                background: $black;
            }
        }
        &--small {
            display: block;
            @include breakpoint(medium) {
                display: none;
            }
        }
        &__collapsible-title .fa, &__expendable-title .fa, &__side-panel {
            display: none;
        }
        &__first-level {
            @include breakpoint(medium) {
                position: relative;
            }
            &__link {
                @include breakpoint(medium) {
                    padding: 1em 2.5em 1em 1em;
                    color: $white;
                    background: $black;
                    &:hover {
                        background: $dropdown-background;
                        color: $black;
                    }
                    &--active {
                        background: $dropdown-background;
                        color: $black;
                    }
                }
                span {
                    display: none;
                    @include breakpoint(medium) {
                        display: inline-block;
                        position: absolute;
                        top: 1em;
                        right: 0.5em;
                        font-size: 120%;
                        color: $dark-gray;
                    }
                }
            }
        }
        &__dropdown {
            @include breakpoint(medium) {
                background: $dropdown-background;
                padding: 15px 0;
                overflow: visible;
                border: 1px solid $light-gray;
                border-top: 0;
                box-shadow: 4px 4px 7px rgba(0,0,0,0.2);
                display: none;
                position: fixed;
                left: 0;
                top: 88px;
            }
            @include breakpoint($additional_intnav_breakpoint) {
                position: absolute;
                top: 44px;
            }
            ul {
                @include breakpoint(medium) {
                    overflow: hidden;
                }
            }
            li {
                display: block;
            }
            // creating classes: column1, column2, column3, column4 and setting appropriate width based on the number of columns
            @for $i from 1 through 4 {
                &.column#{$i} {
                    @include breakpoint(medium) {
                        width: 100%;
                        .internal-navigation__column {
                            width: 100% / $i !important;
                        }
                    }
                    @include breakpoint($additional_intnav_breakpoint) {
                        width: 180px * $i !important;
                    }
                }
            }
            .internal-navigation__collapsible-title {
                @include breakpoint(medium) {
                    font-style: italic;
                    color: $extra-dark-gray;
                    &:hover {
                        background: $light-gray;
                        color: $extra-dark-gray;
                    }
                    &--active {
                        border-top: 1px solid $mid-medium-gray;
                        background: $mid-light-gray;
                        & + ul li:last-child {
                            border-bottom: 1px solid $mid-medium-gray;
                        }
                        &:hover {
                            background: $mid-light-gray;
                        }
                    }
                    .fa {
                        display: inline-block;
                        margin-left: 7px;
                    }
                }
            }
            .internal-navigation__expendable-title {
                @include breakpoint(medium) {
                    font-style: italic;
                    color: $extra-dark-gray;
                    &:hover {
                        background: $mid-light-gray;
                        color: $extra-dark-gray;
                    }
                    &--active {
                        background: $mid-medium-gray;
                    }
                    .fa-caret-down {
                        display: inline-block;
                        margin-left: 7px;
                    }
                    .fa-caret-right {
                        display: none;
                    }
                }
                @include breakpoint(large) {
                    .fa-caret-down {
                        display: none;
                    }
                    .fa-caret-right {
                        display: inline-block;
                        margin-left: 7px;
                    }
                }
            }
            .internal-navigation__side-panel {
                @include breakpoint(large) {
                    display: none;
                    position: fixed;
                    z-index: 905;
                    background: $mid-medium-gray;
                    padding-top: 1em;
                    box-shadow: 4px 4px 7px rgba(0,0,0,0.2);
                    li {
                        float: none;
                        display: block;
                    }
                    a {
                        background: $mid-medium-gray;
                        &:hover {
                            background: $extra-light-blue;
                            color: $medium-blue;
                        }
                    }
                }
            }
        }
        &__column {
            @include breakpoint(medium) {
                background: $dropdown-background;
                border-right: 1px solid $medium-gray;
                float: left;
                &:last-child {
                    border-right: 0;
                }
            }
            li, ul {
                @include breakpoint(large) {
                    position: static;
                }
            }
        }
        span.internal-navigation__expendable-title {
            @include breakpoint(large) {
                display: block;
                padding: 6px 2em;
                background: $mid-light-gray;
                color: $extra-dark-gray;
                cursor: pointer;
                position: relative;
                &--active {
                    background: $mid-medium-gray;
                }
                span {
                    position: absolute;
                    top: 0.6em;
                    right: 0.25em;
                    font-size: 120%;
                }
                &:hover {
                    background: $mid-medium-gray;
                }
            }
        }
        &__third-level, &__fourth-level {
            @include breakpoint(medium) {
                float: none;
            }
        }
        &__fourth-level {
            @include breakpoint(medium) {
                a {
                    padding-left: 2em;
                    background: $mid-light-gray;
                }
            }
            @include breakpoint(medium only) {
                a.internal-navigation__collapsible-title--active {
                    background: $mid-medium-gray;
                }
                a.internal-navigation__expendable-title:hover {
                    background: $mid-medium-gray;
                }
                a.internal-navigation__collapsible-title:hover, a.internal-navigation__collapsible-title--active:hover {
                    background: $medium-gray;
                }
            }
        }
        &__fifth-level-container {
            @include breakpoint(medium) {
                display: none;
            }
        }
        &__fifth-level {
            a {
                @include breakpoint(medium) {
                    padding-left: 4em;
                }
                @include breakpoint(medium only) {
                    background: $mid-medium-gray;
                }
                @include breakpoint(large) {
                    color: $primary-color;
                    padding: 6px 15px;
                }
            }
        }
    }
}