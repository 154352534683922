/* NH and DC AQP list */

#stakeholder-list-content,
#aqp_stakeholder_list {
    padding: 0;
    & > .columns {
        padding: 0;
    }
    p.noresult-message {
        margin: 0.5rem 0;
    }
    .button-container + .counter-box {
        @include breakpoint(medium) {
            text-align: right;
        }
    }
    #stakeholder-list {
        margin-bottom: 10px;
        .nh-new-list {
            position: relative;
            padding: 15px 0;
            overflow: visible;
            margin: 10px 0;
            @include breakpoint(medium) {
                padding: 20px 10px;
            }
            &:first-child {
                margin-top: 0;
            }
            p {
                margin: 0.3rem 0;
                &.tiers {
                    margin: 0;
                }
                &.local-authority {
                    margin: 0;
                }
            }
            &.non-reporting-home {
                background: $extra-light-gray;
                color: $extra-dark-gray;
                h4,
                .label.secondary {
                    color: $extra-dark-gray;
                }
                .label,
                .cqc-rating-box {
                    opacity: 0.8;
                }
                .label.secondary {
                    background: #d9d9d9;
                    color: $extra-dark-gray;
                }
            }
            h3 {
                &.name {
                    display: inline-block;
                    margin: 0.3rem 15px 0.3rem 0;
                    padding: 0;
                    color: $primary-color;
                    font-size: 1.4rem;
                }
                &.non-aqp-home {
                    color: $extra-dark-gray;
                }
                &.aqp-home:hover {
                    text-decoration: underline;
                }
            }
            h4.provider {
                font: {
                    size: 1rem;
                    style: normal;
                }
                margin: 0 0 0.7rem 0;
            }
            .contact-information {
                margin-top: 0.7rem;
            }
            .overview {
                margin-top: 0.7rem;
            }
            .warning-badge {
                margin: 0.2rem 1rem 0.5rem 1rem;
                position: relative;
            }
            .care-tiers strong {
                display: inline-block;
                margin-right: 0.7rem;
                margin-top: 0.5em;
            }
            .list-first-box {
                margin-top: 0;
                @include breakpoint(large) {
                    border-right: 1px solid $medium-gray;
                }
            }
            .list-second-box {
                margin-top: 0;
                padding-top: 1em;
                @include breakpoint(large) {
                    margin-bottom: 0;
                    padding: {
                        bottom: 60px;
                        left: 1.5rem;
                        top: 5rem;
                    }
                }
            }
        }
        @include breakpoint(large) {
            .services-box {
                float: left;
            }
            .btn-box2 {
                text-align: right;
                margin-top: 0.5rem;
                position: absolute;
                bottom: 0;
                right: 0;
            }
            .svg__list {
                position: absolute;
                top: 0;
                right: 0;
                float: none;
                width: auto;
                z-index: 2;
            }
        }
    }
    #filter-box {
        #stakeholderlist-search-panel {
            @include breakpoint(medium) {
                padding-right: 15px;
            }
            fieldset {
                margin-bottom: 10px;
            }
            legend {
                background: transparent;
            }
            .callout {
                @include breakpoint(medium) {
                    padding: 1em;
                }
            }
        }
        #stakeholderlist-filter-panel {
            @include breakpoint(medium) {
                padding-right: 15px;
            }
            fieldset {
                margin-bottom: 10px;
            }
            legend {
                background: transparent;
            }
            .content-title {
                margin-bottom: 0.5rem;
            }
            .callout {
                @include breakpoint(medium) {
                    padding: 1em;
                }
            }
            .filter-titles {
                position: relative;
                cursor: pointer;
                border-top: 1px solid #d9d9d9;
                padding: 0.75rem 0;
                span {
                    position: absolute;
                    top: 1rem;
                    left: 0;
                    margin-left: -3px;
                }
                strong {
                    display: inline-block;
                }
            }
            #aqp_status-radiobuttons .filter-titles,
            #care-tiers-checkbox .filter-titles {
                border: none;
            }
            #main {
                .filter-titles,
                & > div {
                    margin-bottom: 0;
                }
            }
            input[type="checkbox"],
            input[type="radio"] {
                margin: 0;
                margin-right: 0.2rem;
            }
            .collapsible-filters div {
                margin-bottom: 0.8rem;
            }
            .fa {
                display: inline-block;
                margin-right: 5px;
            }
            .clear-all-button {
                position: absolute;
                right: 0;
                top: -2px;
            }
            .clear-button,
            .select-button {
                position: absolute;
                right: 0.5em;
                top: 0;
            }
            .select-button {
                top: -0.75em;
            }
            .collapsible-filters .filter__list__title {
                padding-right: 100px;
                .svg-inline--fa {
                    font-size: 14px;
                    position: relative;
                    left: 3px;
                }
                strong {
                    padding-left: 20px !important;
                }
            }
            #filter-London {
                & > .filter__list {
                    margin-left: 1em;
                    & > div {
                        margin-bottom: 0.5rem;
                    }
                    .filter__list {
                        margin-top: 0.5em;
                    }
                    .filter__list__title {
                        margin-bottom: 0;
                        strong {
                            font-weight: normal;
                        }
                        .svg-inline--fa {
                            font-size: 14px;
                        }
                    }
                    .select-button {
                        @extend .button.tiny;
                        top: 0;
                        margin: 0;
                    }
                }
            }
        }
    }
    .vacancy-prompt-button-box {
        @include breakpoint(large) {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 50%;
        }
    }
    .ant-tree-radio {
        height: auto !important;
        margin: 4px 4px 2px 2px !important;
        padding: 0 !important;
    }
    .ant-tree-node-content-wrapper {
        white-space: normal;
        height: auto !important;
    }

    .ant-tree-switcher {
        float: right;
    }

    .tree-node-title {
        & > ul {
            padding: 0;
        }
        & > .ant-tree-node-content-wrapper {
            padding: 0;
        }
    }

    .ant-tree-title {
        vertical-align: middle !important;
        padding: 0;
    }

    span.ant-tree-checkbox {
        padding: 2px 0;
    }

    @media screen and (max-width: 1200px) {
        .column-border {
            border-bottom: 1px solid #e8e8e8;
        }
        .placement-graph {
            height: 400px;
        }
        .ant-card.graph-margin-bottom {
            margin-bottom: 18px;
        }
        .padding-top-on-resize {
            padding-bottom: 18px;
        }
    }

    .ant-descriptions-view {
        td {
            padding: 0 0 16px 0;
        }
        .no-after {
            .ant-descriptions-item-label:after {
                content: none;
            }
        }
    }

    .ant-descriptions-item > span {
        display: inline;
    }

    .ccg-tables {
        .ant-descriptions-item-content {
            float: right;
        }
    }

    .cqc-report {
        td {
            padding: 8px 16px;
        }
    }
    .ant-descriptions-row {
        background-color: white;
    }

    tbody {
        border: none;
    }

    table {
        margin: 0;
    }
}
