#carepulse_statistics {
    @media print {
        .internal-page__header {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
        .map-box {
            width: 11.5% !important;
            height: 35px !important;
            margin: 0.5% !important;
            strong {
                font-size: 11px;
                top: -2px;
            }
            span {
                position: relative;
                top: -4px;
                font-size: 11px;
            }
        }
        .vacancy-map {
            padding: 0.5em;
        }
        .chart__title h4 {
            margin-top: 0.5em;
            font-size: 85% !important;
        }
        .chart__title h6 {
            font-size: 80% !important;
        }
        canvas {
            width: 100% !important;
            height: 275px !important;
        }
        .chart__box {
            margin: 10px 0 !important;
        }
        .chart__sizing {
            height: 275px !important;
            padding-left: 0 !important;
            margin-top: 0 !important;
        }
        .chart__sizing > div {
            width: 100% !important;
        }
        .menu {
            li:not(.active) {
                display: none;
            }
            li.active {
                a {
                    padding: 0 !important;
                    color: $medium-gray;
                    margin: 1em 0 0.5em 0 !important;
                }
            }
        }

    }
}