#cost_model_homepage {
    .navbar {
        &__lastsaved {
            padding: 1em 25px 1em 30px;
            border-top: 1px solid $navigation-border;
            margin-top: 10px;
        }
    }
    .section {
        &__title {
            border-bottom: 1px solid $light-gray;
            padding: 0 0.5em 0.65em;
            margin: 0 -1em 0.25em -1em;
            color: $blue-title;
            @include breakpoint(medium) {
                margin: 0 -1.5em 0 -1.5em;
            }
            h5 {
                margin-bottom: 0.1em;
            }
        }
        &__question-container {
            margin-bottom: 0.5em;
            margin-top: 0.5em;
            border-bottom: 1px solid $light-gray;
            padding-bottom: 1em;
            &:last-child {
                border-bottom: 0;
                padding: 0;
                margin-bottom: 0;
                @include breakpoint(large) {
                    margin-bottom: 0.5em;
                }
            }
            @include breakpoint(large) {
                margin-bottom: 1.5em;
                margin-top: 1.5em;
                padding-bottom: 1.5em;
            }
        }
        &__question {
            margin-top: 0;
            margin-bottom: 0.5em;
            @include breakpoint(large) {
                margin-bottom: 1.5em;
            }
            &:last-child {
                margin: 0;
                @include breakpoint(large) {
                    margin: 0;
                }
            }
            &__guidance {
                position: relative;
                top: 0;
                margin-bottom: 0.5em;
                &-title {
                    font-size: $small-font-size;
                    font-weight: bold;
                    margin-bottom: 0;
                    margin-top: 0.75em;
                    display: none;
                    @include breakpoint(large) {
                        display: block;
                    }
                }
                .alert-box {
                    padding: 0.75em 1em;
                    margin: 0;
                }
                &--top {
                    .alert-box {
                        margin-bottom: 5px;
                    }
                }
                ul {
                    margin-bottom: 0.5em;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            & > .row {
                margin-top: 0.25em;
                @include breakpoint(large) {
                    margin-top: 0.5em;
                }
            }
            .columns {
                position: relative;
            }
            .section__comments-icon {
                svg {
                    position: absolute;
                    right: 0.5em;
                    top: 0.5em;
                    @include breakpoint(large) {
                        right: 0;
                    }
                }
            }
            input {
                margin: 0;
                &:disabled {
                    background: #dddddd;
                }
            }
            svg + .section__question-input {

                padding-right: 28px;
                @include breakpoint(large) {
                    padding-right: 18px;
                }
            }
        }
        &__question--single {
            margin-bottom: 0;
            padding-bottom: 0.5em;
            .section__question-subtitle {
                margin: 0.4em 0;
            }
            .section__question-error {
                left: 0;
            }
        }
        &__question--multiple {
            input {
                @include breakpoint(small only) {
                    position: relative;
                    left: 0.5em;
                    width: 98%;
                }
            }
        }
        &__question-title {
            font-weight: bold;
            margin-bottom: 0;
            @include breakpoint(large) {
                margin-bottom: 1em;
            }
        }
        &__question-subtitle {
            margin: 0.5em 0;
            @include breakpoint(large) {
                margin-bottom: 1em;
                margin-top: 0;
            }
        }
        &__question-text {
            margin: 0.4em 0;
            position: relative;
            left: 0.6em;
        }
        &__question-error {
            margin: 0.25em 0;
            position: relative;
            left: 0.6em;
            span {
                margin: 0;
            }
        }
        &__comments-icon {
            color: $primary-color;
        }
        &__input-container {
            padding-right: 40px;
            @include breakpoint(large) {
                padding-right: 30px;
            }
        }
        .input-group {
            margin: 0;
        }
        .input-group-label {
            font-size: $small-font-size;
        }
    }
    .submission {
        &__title {
            margin: {
                bottom: 0.5em;
                left: 0;
                right: 0;
            }
            .columns:first-child {
                padding-left: 0;
            }
            .columns:last-child {
                padding-left: 0;
                @include breakpoint(large) {
                    padding-right: 0;
                }
            }
        }
        &__row {
            padding: 0.5em 0;
            margin: {
                left: 0;
                right: 0;
            }
            .button-group {
                @include breakpoint(medium down) {
                    margin: 0.75em 0 0.25em 0;
                    text-align: center;
                }
                @include breakpoint(large) {
                    text-align: right;
                }
                .button {
                    margin: 0.2em 5px 0.2em 0;
                    @include breakpoint(large) {
                        margin: 0.2em 0 0.2em 5px;
                    }
                }
            }
        }

    }
    .declaration {
        input[type="checkbox"] {
            position: relative;
            top: -2px;
            margin-right: 5px;
        }
    }
    .navbar::-webkit-scrollbar {
        width: 5px;

        &-thumb {
            border-radius: 20px;
            background: orange;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        }
    }

    .floating__error {
        @include breakpoint(large) {
            padding-left: 198px;
        }
    }

    .MJXc-TeX-main-R {
        font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
        font-size: 13px;
    }

    .SingleDatePicker {
        width: 100%;

        input {
            margin: 0;
            box-shadow: none;
            font-size: 16px;
            color: black;
            font-style: normal;
        }

        .DateInput {
            width: 100%;
        }

        .SingleDatePickerInput {
            width: 100%;
            border: 1px solid rgb(204, 204, 204);
        }

        .CalendarDay__selected {
            background: #2980b9;
            border: 1px solid #2980b9;
        }
    }
}