#nh-quality-dashboards {
  #Dashboards > div {
    width: 100%;
  }
  // dashboards tabs
  .tabs {
    margin: 0.75rem 0 0 0;
    padding: 0;
    border: 0;
    background: transparent;
    .tab-title {
      text-align: center;
      display: inline-block;
      width: 33.33%;
      border: 1px solid $panel-border;
      background: #fff;
      position: relative;
      min-height: 60px;
      margin: {
        right: auto;
        left: auto;
      }
      @include breakpoint(large) {
        width: 16.66%;
        border-left: 0;
      }
      & > a {
        color: $grey-text;
        padding: 1rem 5px;
        display: block;
        position: absolute;
        top: 1px;
        width: 100%;
      }
      &:first-child {
        @include breakpoint(large) {
          border-left: 1px solid $panel-border;
          border-radius: 5px 0 0 5px;
        }
      }
      &:last-child {
        @include breakpoint(large) {
          border-radius: 0 5px 5px 0;
        }
      }
      &.active {
        background: scale-color($light-blue, $lightness: +75%);
        a {
          color: $body-font-color;
          font-weight: bold;
        }
      }
      &:hover {
        background: #fff;
        a {
          color: #666;
          font-weight: bold;
        }
      }
      &.active:hover {
        background-color: scale-color($light-blue, $lightness: +75%);
        a {
          color: $body-font-color;
        }
      }
    }
    &-ul__margin {
      margin-bottom: 20px;
    }
  }

  //dashboards navigation and settings panel
  .secondary-navigation {
    &__path {
      .secondary-navigation__settings-text {
        display: none;
        padding-left: 5px;
        @include breakpoint(medium) {
          display: inline-block;
        }
      }
      span {
        display: inline;
        @include breakpoint(medium) {
          display: inline-block;
        }
      }
    }
    &__dropdown {
      &__list-item {
        &.closed:after {
          content: "Closed";
          display: inline-block;
          margin-left: 10px;
          font-size: 74%;
          padding: 1px 7px 0px;
          background: #c60f13;
          color: $light-text;
          border-radius: 50px;
        }
      }
      &--settings {
        width: 280px;
        height: auto;
        position: absolute;
        top: 29px;
        right: 0;
        border-radius: 5px 0 5px 5px;
        background: #fff;
        padding: 0;
        @include breakpoint(medium) {
          width: 400px;
        }
        @include breakpoint(large) {
          padding-left: 0;
        }
        .dashed-pattern {
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAJCAYAAAALpr0TAAAAJUlEQVQoU2NkIBIwEqmOgRYKGxj+47S+AWEjIwPRCon0DfU9AwDCjAQKnPF2AgAAAABJRU5ErkJggg==) repeat-x;
          margin-top: 6px;
        }
        .solid-pattern {
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAJCAYAAAALpr0TAAAAJElEQVQoU2NkIBIwEqmOgRYKGxj+E2M9IwPRCokxjoGBBp4BACbbAwghC7C2AAAAAElFTkSuQmCC) repeat-x;
          margin-top: 6px;
        }
      }
    }
    &__dropdown-box {
      &--settings {
        @extend .secondary-navigation__dropdown-box;
        padding: 0.75em 0.75em 1em 0.75em;
        background: transparent;
        border: 0;
        @include breakpoint(medium only) {
          padding: 1em;
        }
        @include breakpoint(large) {
          padding: 1em;
        }
      }
    }
  }

  @include breakpoint(medium down) {
    #nav-dropdown-column3.secondary-navigation__dropdown__column {
      border-right: 0;
    }
  }
  #nav-dropdown-column0 {
    width: 50%;
    display: block;
  }

  #nav-dropdown-column1 {
    width: 50%;
    display: block;
  }

  #nav-dropdown-column2 {
    width: 50%;
    display: none;
  }

  #nav-dropdown-column3 {
    width: 50%;
    display: none;
  }

  #nav-dropdown-column4 {
    width: 100%;
    display: block;
    border: 0;
    text-align: center;
    position: relative;
    padding: 0 15px;
    button {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  @include breakpoint(medium) {
    #nav-dropdown-column0 {
      width: 18%;
      display: block;
    }

    #nav-dropdown-column1 {
      width: 14%;
      display: block;
    }

    #nav-dropdown-column2 {
      width: 28%;
      display: block;
    }

    #nav-dropdown-column3 {
      width: 40%;
      display: block;
    }
    #nav-dropdown-column4 {
      top: 5px;
    }
  }

  @include breakpoint(large) {
    #nav-dropdown-column0 {
      width: 15%;
    }

    #nav-dropdown-column1 {
      width: 9%;
    }

    #nav-dropdown-column2 {
      width: 19%;
    }

    #nav-dropdown-column3 {
      width: 30%;
    }

    #nav-dropdown-column4 {
      width: 27%;
      display: inline-block;
      height: 100%;
      top: 0px;
      float: left;
      padding: 0 20px;
      button {
        margin-top: 1em;
        margin-bottom: 25px;
      }
    }
  }

  // control line styling in graph settings
  //TO DO: rewrite to BEM
  #upper-toggle-button, #mean-toggle-button, #lower-toggle-button {
    width: 30px;
    height: 10px;
    position: relative;
    top: 2px;
    margin: {
      right: 5px;
      left: 5px;
    }
  }
  .legend-div-container > div > input[type=checkbox] {
    visibility: hidden;
    width: 0px;

  }
  .legend-div-container > div > input[type=checkbox] + label {
    margin: {
      right: 0px;
      left: 7px;
    }
    padding: 5px 0px 5px 34px;
    border-radius: 100px;
    color: #000000;
    background-color: #ffffff;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
  }
  .legend-div-container > div > label::before {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 10px;
    width: 18px;
    height: 18px;
    border: 2px solid #999999;
    transition: background-color .2s;
  }
  .legend-div-container > div > label:first-of-type {
    transform: translateX(20px);
  }
  .legend-div-container > div > label:last-of-type {
    transform: translateX(-20px);
  }
  .legend-div-container > div > input:checked + label::before {
    background-color: #1863ad;
    border: 2px solid #cccccc;
  }
  .functionality, .show-hide-container, .overview-inner-spacing {
    padding: 0 10px 10px 0;
  }
  .legend-div-container {
    margin-top: 10px;
    padding: 0;
    overflow: hidden;
  }
  .legend-div-container div {
    float: left;
  }
  .legend-div-container-chart div {
    margin-top: -1.65rem;
    z-index: 0;
  }

  // Slider styling
  input[type="text"][readonly]#amount {
    background-color: transparent;
    box-shadow: none;
    margin: {
      bottom: 0.3rem;
      top: 0.3rem;
    }
    padding-left: 0;
  }
  .ui-widget.ui-widget-content {
    border: 1px solid $panel-border;
  }
  .ui-slider.ui-slider-horizontal {
    background: #fff;
    background: rgba(255, 255, 255, 0.5);
  }
  .ui-widget-header {
    background-color: #d4e8f7;
  }
  .ui-slider-handle.ui-state-default {
    cursor: col-resize;
  }
  #custom-handle, #custom-handle2 {
    background-color: #2980b9;
    color: #c0def1;
    text-align: center;
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
  #percentage-completed-div, #percentage-completed-div-1, #percentage-completed-div-2 {
    width: 100%;
    height: 30px;
  }

  // nursing homes info popup
  .nh-info-popup {
    .cqc-rating-div {
      display: block;
      @include breakpoint(medium) {
        display: none;
      }
      @include breakpoint(large) {
        display: block;
      }
    }
    .cqc-rating-column {
      display: none;
      @include breakpoint(medium) {
        display: table-cell;
      }
      @include breakpoint(large) {
        display: none;
      }
    }
    @media only screen and (min-width: 1200px) {
      .cqc-rating-div {
        display: none;
      }
      .cqc-rating-column {
        display: table-cell;
      }
    }
  }

    // nursing homes information box
    .info-box {
        margin: 25px auto 15px auto;
        background: white;
        border: 1px solid $panel-border;
        &__title {
            color: $blue-title;
            font-size: 1.4rem;
        }
        &__punctuality {
            display: inline-block;
            padding: 5px 10px;
            color: $light-text;
            margin: {
                top: 5px;
                right: 5px;
            }
        }
        & > .row {
            margin: 0 auto;
        }
    }

    // notification box
    .notifications {
        display: inline-block;
        margin-right: 0.5em;
        @include breakpoint(medium) {
            float: right;
        }
        &__dropdown {
            min-height: 20px;
            width: 280px;
            margin-top: 30px;
            left: 0;
            padding: 0.5em;
            padding-top: 0.75em;
            @include breakpoint(medium) {
                width: 380px;
                left: auto;
                right: 0;
            }
        }
        &__button {
            float: right;
            font-weight: bold;
            margin: 0;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 90%;
        }
        li {
            margin: 2px;
            padding: 5px;
        }
    }

  // exception tab
  .exception__list {
    margin: {
      bottom: 0;
      left: 1.1em;
    }
    @include breakpoint(medium) {
      margin-left: 2.5em;
    }
    &-item {
      @include breakpoint(medium) {
        padding-left: 5px;
      }
    }
  }

  .export__modal {
    position: fixed;
    top: 100px;
    background: white;
    border: 1px solid lightgrey;
    width: 60%;
    z-index: 1006;
    margin: auto;
    left: 20%;
    &__inputs {
      &__checkboxes {
        & label {
          margin: auto;
          width: 50%;
          display: inline-block;
          padding: 10px;
        }
      }
    }
  }

  // general styling
  #Dashboards.row, .graph-slider .row {
    margin: 0 auto;
  }
  .public-button.small-public-button {
    font-size: 100%;
  }

}