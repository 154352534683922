/* Login button and panel */

.login {
    display: inline-block;
    float: right;
    position: absolute;
    top: 1px;
    right: 1em;
    @include breakpoint(medium) {
        top: 4px;
        right: 2em;
    }
    &__login-box {
        color: $light-text !important;
        position: absolute;
        top: 35px;
        right: 0;
        width: 180px;
        height: auto;
        border: 1px solid $white;
        border-radius: 5px;
        background: $navigation-background;
        z-index: 605;
        text-align: center;
        padding: 10px;
        box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.5);
        font-size: 0.85em;
        display: none;
        @include breakpoint(medium) {
            top: 33px;
        }
        h6 {
            color: $light-text;
            margin: 0 0 0.5em 0;
        }
    }
    &__login-button {
        position: relative;
        float: none;
        width: 54px;
        overflow: hidden;
        padding: .4rem 1rem .385em;
        @include breakpoint(medium) {
            width: 180px;
        }
        &--dark {
            background: $navigation-background;
            &:hover, &:focus {
                background: $navigation-background;
            }
        }
        .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
            position: absolute;
            right: 0.55em;
            top: 0.3em;
            font-size: 20px;
            @include breakpoint(medium) {
                top: 0.35em;
                right: 7px;
            }
        }
    }
    &__login-link {
        &:link, &:active, &:visited {
            color: $light-text;
        }
        &--logout {
            width: 100%;
            margin: 0;
            &:hover {
                background: $main-blue-lighter-background;
            }
        }
        .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
            margin-right: 3px;
            font-size: 1rem;
        }
    }
    &.inactive {
        .login__login-button {
            border: 0;
            text-align: right;
            width: auto;
            padding-right: 30px;
            padding-top: 0.5rem;
            cursor: default;
            &:hover, &:active, &:focus {
                background: $primary-color;
            }
            .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
                right: 0;
                margin-right: 0;
                top: 0.3em;
            }
        }
    }
}

#user-name {
    display: none;
}

.top-banner__title__button-box.signin-button {
    position: absolute;
    right: 0;
    top: -7px;
    @include breakpoint(medium) {
        display: none;
    }
}