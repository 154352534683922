#progress_update_dashboard {
    .text_header {
        background: #fff;
        text-align: center;
        border: #ddd 1px solid;
        border-radius: 5px;
        padding: 15px 10px;
        & h3 {
            color: $primary-color;
            margin: 5px 0 0;
            font-size: 22px;
        }
        & p {
            font-size: 18px;
            margin-bottom: 0;
            display: inline-block;
            line-height: 1.4;
            padding: 0 5px;
        }
    }
    .header_boxes {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 0.5rem;
    }
}
