/* Scalable Vector Graphics */

.svg {
    &__list {
        display: inline-block;
        position: relative;
        top: 0.75em;
        @include breakpoint(medium) {
            top: 0.25em;
        }
        &.tiny {
            top: 0;
        }
    }
    &__container {
        &--medium {
            display: inline-block;
            position: relative;
            top: -0.5em;
            padding: 5px;
            width: 45px;
            &.no-svg {
                padding: 0;
            }
        }
        &--small {
            display: inline-block;
            position: relative;
            top: 0;
            padding: 4px;
            width: 38px;
            &.no-svg {
                padding: 0;
            }
        }
        &--tiny {
            display: inline-block;
            position: relative;
            top: -2px;
            padding: 2px;
            width: 26px;
            &.no-svg {
                padding: 0;
            }
        }
        &--navigation {
            text-align: center;
            display: inline-block;
            position: relative;
            top: 0em;
            left: 5px;
            padding: 0;
            &.no-svg {
                padding: 0;
            }
        }
    }
    &__icon {
        &--aqp {
            width: 56px;
        }
    }
}

/* Loading icon */

.spinner {
    z-index: 510;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: $overlay-background;
    text-align: center;
    &__inner {
        position: relative;
        top: 35%;
        color: $white;
        .fa, .fas, .far, [class^="fi-"], .svg-inline--fa {
            font-size: 500%;
        }
        p {
            font-size: 20px;
            width: auto;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
}

/* Status icons */

.icon--status {
    color: #808080;
    font-size: 22px;
    border: 1px solid #808080;
    width: 25px;
    height: 25px;
    margin: 2px;
    display: inline-block;
    text-align: center;
    background: transparent;
    border-radius: $global-radius;
    padding: 0;
    &:hover {
        color: $white;
        background: #b3b3b3;
    }
    &.active {
        color: $white;
        background: $primary-color;
    }
     &.tooltip--custom__info-icon {
        font-size: 18px;
        width: 22px;
        height: 22px;
        top: 0;
    }
    &.text--alert {
        border: 1px solid $alert-color;
        &:hover, &.active {
            color: $alert-color;
            background: transparent;
        }
    }
}
