#provider_homepage {
    position: relative;
    .toolkit {
        &__table {
            td {
                padding: 0 .6em !important;
                background: white !important;
                border: 1px solid $panel-border;
            }
            th{
                border: 1px solid $panel-border;
            }
        }
    }
    .faq {
        &__section {
            td {
                padding: 0.6em 1em;
            }
            tr {
                background: transparent;
            }
            &__question {
                cursor: pointer;
                background: transparent;
                border-top: 1px solid $panel-border;
                &:first-child {
                    border: 0;
                }
                td {
                    position: relative;
                }
                &-title {
                    font: {
                        size: 1em;
                        weight: bold;
                    }
                    display: inline-block;
                    padding-right: 40px;
                    margin-bottom: 0;
                    .svg-inline--fa {
                        font-size: $small-font-icon;
                        color: $dark-gray;
                        margin-right: 10px;
                    }
                    & + .svg-inline--fa {
                        position: absolute;
                        right: 0.75em;
                        top: 0.6em;
                        color: $extra-dark-gray;
                    }
                }
                &.expanded {
                    background: #f8f8f8;
                }
            }
            &__answer {
                td {
                    padding: 0 1em 0.5em 2.5em;
                    background: #f8f8f8;
                }
            }
        }
    }
}
