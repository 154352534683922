#signin {
    .signin {
        &__container {
            max-width: 800px;
            margin: {
                left: auto;
                right: auto;
                top: 1em;
            }
            padding: 0;
            & > .row {
                margin: 0;
                & > .columns {
                    padding: 0;
                    &:first-child {
                        border-bottom: 1px solid $panel-border;
                    }
                    @include breakpoint(medium) {
                        &:first-child {
                            border: {
                                bottom: 0;
                                right: 1px solid $panel-border;
                            }
                        }
                    }
                }
            }
        }
        &__column {
            &-container {
                position: relative;
                padding: 1em;
                @include breakpoint(medium) {
                    padding: 1em 1.5em;
                }
                .fa-ul {
                    list-style-type: none;
                    margin-left: 2em;
                    @include breakpoint(medium) {
                        margin-left: 3em;
                    }
                    li {
                        margin-bottom: 5px;
                        @include breakpoint(medium) {
                            margin-bottom: 10px;
                        }
                    }
                    .fa-li {
                        left: -2.1em;
                    }
                }
                .sign-in-button-container {
                    margin: {
                        top: 0.75em;
                        bottom: 1em;
                    }
                }
                .sign-in-button {
                    padding: 10px 50px;
                    margin: {
                        right: 10px;
                        top: 1px;
                        bottom: 5px;
                    }
                    float: left;
                    @include breakpoint(small only) {
                        width: 100%;
                    }
                }
                .forgot-password {
                    padding: 1em 0;
                    display: inline-block;
                }
                .register_text {
                    padding: 0.5em 0;
                }
                p {
                    line-height: 1.4;
                    margin-bottom: 1rem;
                    text-align: justify;
                    &:last-child {
                        margin: 0;
                    }
                    @include breakpoint(medium) {
                        margin-bottom: 1.75rem;
                    }
                }
            }
            &-title {
                font-size: 1.25em;
                color: $primary-color;
                margin: 0.75em 0 1.25em;
                padding-right: 50px;
            }
        }
        &__logo-container {
            width: 230px;
            margin-bottom: 1.5em;
            position: relative;
            left: -5px;
            top: 5px;
            @include breakpoint(medium) {
                margin-bottom: 2.5em;
            }
        }
        &__icon {
            color: $medium-gray;
            font-size: $xlarge-font-icon;
            position: absolute;
            top: 0.65em;
            right: 0.5em;
        }
        //&__stats {
        //    &-title {
        //        font-weight: bold;
        //    }
        //    &-list {
        //        margin: 0 0 15px 0;
        //        li {
        //            list-style-type: none;
        //            position: relative;
        //            padding-right: 85px;
        //            margin: 10px 0;
        //            .label {
        //                position: absolute;
        //                right: 0;
        //                top: -4px;
        //            }
        //        }
        //    }
        //}
        &__twitter-line {
            max-width: 800px;
            margin: {
                left: auto;
                right: auto;
            }
            padding: 0.75em 1em;
            @include breakpoint(medium) {
                padding: 0.75em 1.75em;
            }
            a {
                white-space: nowrap;
            }
            .fa-twitter {
                color: $primary-color;
            }
        }
    }
}