/* Top banner */

.print-logo, #date-print {
  display: none;
}

.top-banner__logo {
  background: #fff;
  overflow: hidden;
  z-index: 1004;
  position: relative;
  @include breakpoint(large) {
    padding-left: 210px;
    transition: all 0.5s;
    z-index: 705;
  }
  &__box {
    position: relative;
    height: 66px;
    margin: 0;
  }
  &__img--small {
    display: block;
    @include breakpoint(retina) {
      display: none;
    }
  }
  &__img--large {
    display: none;
    @include breakpoint(retina) {
      display: block;
    }
  }
  &--carepulse {
    position: absolute;
    left: 1.5rem;
    height: 3.5rem;
    @media screen and (max-width: 520px) {
      position: absolute;
      left: 1rem;
      top: 0.4rem;
    }
    div {
      position: relative;
      background-size: contain;
      background-repeat: no-repeat;
      width: auto;
      min-height: 100%;
    }
    img {
      width: 200px;
      height: auto;
      position: relative;
      top: 8px;
      left: 0;
      @media screen and (max-width: 520px) {
        width: 160px;
        height: auto;
      }
      @media screen and (min-width: 520px) and (max-width: 64em) {
        left: -10px;
      }
    }
  }
  &--lph {
    position: absolute;
    top: 0;
    right: 2rem;
    height: 3.5rem;
    &__img {
      position: relative;
      height: 2rem;
      @media screen and (max-width: 520px) {
        position: relative;
        height: 2rem;
        right: -0.7rem;
        top: 5px;
      }
    }
    &__text {
      font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
      font-size: 14px;
      color: #224C7F;
      font-weight: bold;
      margin-top: 8px;
      margin-right: 2px;
      @media screen and (max-width: 520px) {
        font-size: 10px;
        position: relative;
        top: 8px;
        right: -0.6rem;
      }
    }
    img {
      position: absolute;
      top: 2px;
      right: -10px;
      width: 85px;
      height: auto;
    }
  }
}

.top-banner__title {
  position: relative;
  margin: 1px auto 10px;
  padding: 5px 10px 5px 65px;
  background: $primary-color;
  outline: 1px solid $medium-gray;
  overflow: visible;
  min-height: 42px;
  z-index: 605;
  @include breakpoint(medium) {
    padding: 4px 17px;
    padding-left: 95px;
    transition: all 0.5s;
  }
  @include breakpoint(large) {
    padding-left: 225px;
    transition: all 0.5s;
  }
  &__box {
    @include breakpoint(medium) {
      padding-right: 195px !important;
    }
  }
  &__button-box {
    display: block;
    padding-right: 0;
    margin: 3px 0;
    @include breakpoint(large) {
      position: absolute;
      display: inline-block;
      margin: 0;
      top: 0;
      right: 190px;
    }
  }
  h2 {
    color: $light-text;
    margin: 0 10px 0 0;
    padding: 0.15rem 55px 0 0;
    font-size: 1.1rem;
    line-height: 1.9rem;
    display: inline-block;
    @include breakpoint(medium) {
      font-size: 1.3rem;
      //padding-right: 175px !important;
    }
  }
}