#dc-placement-dashboards {
  #content-wrap {
    margin-top: 1.25em;
  }
  #mainContainer {
    width: 100%;
  }

  #map {
    width: 100%;
    height: 100%;
    min-height: 600px;
    border: 1px solid #ddd;
    z-index: 1;
  }

  .map-info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    //background: rgba(255, 255, 255, 0.8);
    //box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 0.2);
  }

  .map-info h4 {
    margin: 0 0 5px;
    color: #777;
  }

  #charts {
    padding: 0;
    @include breakpoint(large) {
      padding-left: 15px;
    }
  }
  .leaflet-tooltip.sector-labels {
    background-color: transparent;
    border: transparent;
    font-size: 40px;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
    vertical-align: middle;
  }

  .leaflet-tooltip.ccg-labels {
    background-color: transparent;
    border: transparent;
    box-shadow: none;
    font-size: 15px;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    vertical-align: middle;
    white-space: normal;
  }
  .callout--map {
    margin: 0;
    margin-bottom: 20px;
    @include breakpoint(large) {
      margin-bottom: 0;
    }
  }

  .info-box {
    &__title {
      color: $blue-title;
      font-size: 1.4rem;
    }
    &__date {
      display: block;
      position: relative;
      margin-top: 0;
      color: hsl(0, 0%, 50%);
      @include breakpoint(medium) {
        position: absolute;
        top: 0.25em;
        right: 1em;
      }
    }
  }
  .legend {
    line-height: 18px;
    color: #555;
    background: white;
    box-shadow: none;
    border: 2px solid rgba(0, 0, 0, 0.2);
  }
  .legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
  }
  //.dropdown {
  //  width: 100%;
  //  margin-left: 0;
  //  min-height: 50px;
  //  box-shadow: 0 6px 8px 0 hsla(0, 0%, 0%, 0.2);
  //  background: hsl(0, 0%, 95%);
  //  overflow: hidden;
  //  @include breakpoint(medium) {
  //    width: 50%;
  //    margin-left: 7.5%;
  //  }
  //
  //  &__list {
  //    width: 100%;
  //    &__bottom {
  //      width: 100%;
  //      background: hsl(0, 0%, 90%);
  //      padding: 10px;
  //      @include breakpoint(medium) {
  //        padding: 10px 0;
  //      }
  //    }
  //
  //    &__top {
  //      background: hsl(0, 0%, 95%);
  //    }
  //    &--active {
  //      font-weight: 700;
  //      background: hsl(0, 0%, 90%);
  //      border-left: 3px solid $primary-color !important;
  //    }
  //  }
  //
  //  &__sector {
  //    width: 100%;
  //    height: 50%;
  //    list-style: none;
  //    text-align: center;
  //    margin: 0;
  //    & li {
  //      display: inline-block;
  //      text-align: center;
  //      cursor: pointer;
  //      padding: 10px 0;
  //      border-left: 3px solid hsl(0, 0%, 95%);
  //      width: calc(100% / 5);
  //      color: hsl(0, 0%, 17%);
  //      &:hover {
  //        font-weight: 700;
  //        border-left: 3px solid $primary-color;
  //        background: hsla(0, 0%, 0%, 0.05)
  //      }
  //    }
  //
  //  }
  //
  //  &__ccg {
  //    width: 100%;
  //    height: 50%;
  //    margin: 0;
  //    list-style: none;
  //    text-align: left;
  //    & li {
  //      display: inline-block;
  //      text-align: center;
  //      cursor: pointer;
  //      border-left: 3px solid hsl(0, 0%, 90%);
  //      width: calc(100% / 3);
  //      max-width: calc(100% / 3);
  //      color: hsl(0, 0%, 40%);
  //      &:hover {
  //        font-weight: 700;
  //        border-left: 3px solid $primary-color;
  //        background: hsla(0, 0%, 0%, 0.05)
  //      }
  //    }
  //  }
  //}

  .secondary-navigation {
    .custom-list__selected {
      border: 1px solid rgb(179, 179, 179);
    }
    @include breakpoint(large) {
      .custom-list {
        padding-left: 0;
        display: inline-block;
        float: right;
      }
      .custom-list__select {
        margin: 0;
        position: relative;
        top: -2px;
        right: -0.5em;
      }
    }
  }

  .info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  .info h4 {
    margin: 0 0 5px;
    color: #777;
  }

  .reset-button {
    font-size: 16px;
  }

  .reset-button a {
    line-height: 40px;
    width: 140px;
    height: 40px;
    display: none;
  }

  .info-box {
    &__title {
      color: $blue-title;
      font-size: 1.4rem;
    }
    &__date {
      display: block;
      position: relative;
      margin-top: 0;
      color: hsl(0, 0%, 50%);
      @include breakpoint(medium) {
        position: absolute;
        top: 0.25em;
        right: 1em;
      }
    }
  }
  .tooltip--custom__popup-wrap {
    z-index: 806;
  }
  .tooltip--custom__popup {
    width: auto;
    min-width: auto;
    white-space: nowrap;
    z-index: 806;
  }
  #table .table-container:first-child {
    @include breakpoint(large) {
      padding-right: 0;
    }
  }
}