#provider_homepage {
    .info-box {
        margin: 25px auto 25px auto;
        background: white;
        border: 1px solid lightgrey;
    }

    .info-box__title {
        color: #003087;
        font-size: 1.4rem;
    }

    .info-box__first {
        display: inline-block;
        margin: 0.5em 0;
    }

    .info-box__first span {
        border-radius: 5px;
        color: white;
        margin: 5px 10px 5px 0;
        height: 30px;
        padding: 7px 10px;
    }

    .columns--form {
        padding-left: 0;
    }

    [class*="column"] + [class*="column"]:last-child {
        float: left;
    }

    .columns--float-right {
        float: right !important;
    }

    .panel-box--options {
        border-right: 0;
        border-top: 1px #ccc solid;
        margin-top: 0;
        padding-top: 27px;
        padding-bottom: 15px;
    }

    .panel-box__item {
        border-top: 1px solid #ccc;
        padding-top: 21px;
        padding-bottom: 21px;
        border-right: none;
        margin-top: 0;
        margin-bottom: 0;
    }

    .fa-chevron--custom {
        right: 4%;
        padding-top: 1.6%;
        float: right;
    }

    .reporting_period {
        margin-right: 15px;
        display: inline-block;
    }

    .service_location_link {
        margin-right: 15px;
        display: inline-block;
    }

    @media screen and (max-width: 850px) {
        .reporting_period {
            margin-right: 0;
            display: block;
        }
        .service_location_link {
            display: block;
        }
    }

    .start_placement_button {
        margin-left: 10px;
    }

    @media screen and (max-width: 675px) {
        .start_report_button {
            display: block;
        }

        .start_placement_button {
            margin-left: 0;
        }
    }

    .home-services-box p {
        margin: 1rem 0;
    }
    #cqc_rating table td,
    #staff_member_table td,
    #cqc_rating table th,
    #staff_member_table th {
        font-size: 100%;
    }
    #staff_member_table {
        border: none;
        margin-left: -0.625rem;
        table-layout: fixed;
    }
    .st-val {
        width: 100%;
    }
    .st-key {
        padding-right: 0.75em;
    }
    #staff_member_table thead,
    #staff_member_table tbody,
    #staff_member_table tfoot {
        border: none;
    }
    #staff_member_table tr,
    #staff_member_table thead {
        background: transparent;
    }
    #contacts-info {
        margin: 0;
    }
    #contacts-info ul.pagination {
        display: none;
    }
    #reg-activities + ul .fi-info {
        display: none;
    }
    #reg-activities + ul .fi-info:before {
        content: "";
    }
    #reg-activities {
        margin: 1em 0 0.5em 0;
    }
    #other-specialism span {
        white-space: nowrap;
    }

    /* Removing link style */
    .full_name a,
    .job_title a {
        color: #333;
        cursor: default;
    }
    table thead tr th,
    table tbody tr td {
        color: #333;
    }

    .pagination li.cardinality {
        display: none;
    }
    p.tiers {
        margin: 0;
        color: #fff;
    }
    .callout {
        padding-top: 0.5rem;
    }
    .callout h5 {
        margin: 0.5rem 0;
        font-size: 100%;
    }
    .callout p.services-para {
        margin: 0;
    }
    .callout ul {
        margin-left: 0.75rem;
    }
    .callout li {
        margin-left: 0.5rem;
    }
    .home-services-box ul {
        margin-bottom: 0;
    }
    .home-services-box h5 {
        margin-top: 0.5rem;
    }
    .has-tip {
        font-weight: normal;
    }

    .cqc-rating-div {
        display: block;
    }
    .cqc-rating-column {
        display: none;
    }
    @media only screen and (min-width: 40.063em) {
        .cqc-rating-div {
            display: none;
        }
        .cqc-rating-column {
            display: table-cell;
        }
    }
    @media only screen and (min-width: 64.063em) {
        .cqc-rating-div {
            display: block;
        }
        .cqc-rating-column {
            display: none;
        }
    }
    @media only screen and (min-width: 1380px) {
        .cqc-rating-div {
            display: none;
        }
        .cqc-rating-column {
            display: table-cell;
        }
    }

    #page_subtitle_box {
        padding: 0;
    }
    .provider-homepage__vacancy-form {
        &__label {
            text-align: center;
            @include breakpoint(medium) {
                text-align: right;
                display: inline-block;
                padding: 0 0.15em;
                position: relative;
                top: 5px;
            }
        }
        &__input {
            .button--control__box {
                @include breakpoint(medium) {
                    margin-left: 0;
                }
            }
        }
    }
}