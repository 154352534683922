/* print layout for quality questionnaire page */

@media print {
  #quality-questionnaires {
    .button {
      display: none;
    }
    #content-wrap {
      padding-left: 1em !important;
    }
    .section {
      border: 0;
      margin-bottom: 0;
      padding: 10px 0 0 0;
      & > div {
        top: 0 !important;
      }
      &__question input {
        margin: 5px 0;
      }
      &__title {
        border: 0;
        border-top: 1px solid $medium-gray;
        padding: 0;
        margin: {
          right: 0;
          left: 0;
        }
        padding-top: 1em;
        page-break-inside: avoid;
        h5 {
          padding: 0;
        }
      }
    }
    .section__question-alltext, .section__question-text, .multi-question {
      page-break-inside: avoid;
    }
    input {
      min-width: 50px !important;
      page-break-inside: avoid;
    }
    .placement {
      border: 0;
      padding: 0;
      &__title {
        border-bottom: 0;
        padding: {
          left: 0;
          bottom: 0;
        }
        margin: 0;
        margin-top: 1em;
      }
      &__table {
        border: 0;
        &__row {
          page-break-inside: avoid;
        }
      }
      &__table-button {
        width: 50px;
        right: 0;
        top: -0.5em;
      }
      &__table-row:nth-child(2n) {
        background: #f2f2f2 !important;
      }
      input {
        min-width: 70px !important;
      }
      .DateInput_input__small {
        min-width: 100px !important;
      }
      .section__comments-icon {
        position: relative;
      }
    }
    .info-box__title {
      font-size: 1.25rem !important;
    }
  }
}
