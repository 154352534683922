.chart {
    &__box {
        margin: 10px 0;
        border: 1px solid $panel-border;
        background-color: $white;
        border-radius: $global-radius;
        overflow: visible;
        @include breakpoint(medium) {
            margin: 20px 0;
        }
        h4 {
            padding: 0;
            position: relative;
            font-size: 100%;
            font-weight: bold;
            margin: 0.75rem 0 0.15rem;
            @include breakpoint(medium) {
                margin: 0.75rem 0 0.15rem;
            }
            @include breakpoint(large) {
                margin: 1.5rem 0 0.15rem;
            }
        }
        h6 {
            padding: 3px 0 0 0;
            position: relative;
            font-size: 87.5%;
            margin: 0;
            font-style: italic;
        }
    }
    &__title {
        border-bottom: 1px solid $panel-box-border-color;
        margin: 0 1em;
        padding-bottom: 0.5em;
        @include breakpoint(large) {
            margin: 0 1.5em;
        }
        &__header {
           vertical-align: top;
           margin: 5px;
           display: none;
           @include breakpoint(1500px) {
             display: inline;
           }
        }
    }
    &__sizing {
        width: 100%;
        height: 300px;
        margin-top: -25px;
        &--small {
            height: 150px;
        }
        &--medium {
            height: 250px;
        }
        &--large {
            height: 300px;
        }
        &--xlarge {
            height: 350px;
        }
    }
    &__popup {
        max-width: 100%;
        min-width: 200px;
        @include breakpoint(large) {
            max-width: 350px;
        }
    }
}

